export const deviceTypeOptions = [
  {
    value: 1,
    label: "Bluetooth"
  },
  {
    value: 2,
    label: "Cellular"
  },
  {
    value: 3,
    label: "Manual"
  }
];
