import { useEffect, useRef, useState } from "react";
import * as signalR from "@microsoft/signalr";

const JitsiCallComponent = ({ userId, callerId, receiverId, callType }) => {
  const [isCallActive, setIsCallActive] = useState(false);
  const [incomingCall, setIncomingCall] = useState(null);

  const apiRef = useRef(null);
  const connectionRef = useRef(null);

  useEffect(() => {
    // create connection with signalR while component mount

    const connection = new signalR.HubConnectionBuilder()
      .withUrl()
      .withAutomaticReconnect()
      .build();

    connectionRef.current = connection;
    connection
      .start()
      .catch((err) => console.log("SignalR Connection Error", err));

    connection.on("Received Offer", (callOffer) => {
      if (callOffer.receiverId === userId) {
        setIncomingCall(callOffer);
      }
    });

    return () => {
      connection.stop();
    };
  }, [userId]);

  const roomName = `call_${callerId}_${receiverId}_${Date.now()}`;

  const handleStartCall = async () => {
    try {
      await connectionRef.current.invoke("SenderCallOffer", {
        callerId,
        receiverId,
        callType,
        roomName
      });
      setIsCallActive(true);
    } catch (error) {
      console.log("Error sending Call offer", error);
    }
  };

  const handleRejectCall = async () => {
    try {
      await connectionRef.current.invoke("RejectCall", incomingCall);
      setIncomingCall(null);
    } catch (error) {
      console.log("Error rejecting call: ", error);
    }
  };

  const handleApiReady = (apiObj) => {
    apiRef.current = apiObj;

    if (callType === "audio") {
      apiRef.current.executeCommand("toggleVideo");
    }

    apiRef.current.on("videoConferenceJoined", () => {
      console.log("Call joined");
    });

    apiRef.current.on("videoConferenceLeft", () => {
      console.log("Call left");
      setIsCallActive(false);
    });
  };

  const handleReadyToClose = () => {
    console.log("Call ended");
    setIsCallActive(false);
  };

  const handleAcceptCall = () => {
    setIsCallActive(true);
    setIncomingCall(null);
  };

  const handleIFrameRef = (iframeRef) => {
    iframeRef.style.height = "600px";
    iframeRef.style.width = "800px";
  };

  if (incomingCall) {
    return (
      <div>
        <h2>
          Incoming {incomingCall.callType} call from {incomingCall.callerId}
        </h2>
        <button onClick={handleAcceptCall}>Accept</button>
        <button onClick={handleRejectCall}>Reject</button>
      </div>
    );
  }

  if (!isCallActive) {
    return (
      <button onClick={handleStartCall}>
        Start {callType === "audio" ? "Audio" : "Video"} Call
      </button>
    );
  }

  return (
    <JitsiCallComponent
      domain="meet.jit.si"
      roomName={roomName}
      configOverwrite={{
        startWithAudioMuted: false,
        startWithVideoMuted: callType === "audio",
        prejoinPageEnabled: true
      }}
      interfaceConfigOverwrite={{
        TOOLBAR_BUTTONS: [
          "microphone",
          "camera",
          "closedcaptions",
          "desktop",
          "fullscreen",
          "fodeviceselection",
          "hangup",
          "profile",
          "chat",
          "recording",
          "livestreaming",
          "etherpad",
          "sharedvideo",
          "settings",
          "raisehand",
          "videoquality",
          "filmstrip",
          "invite",
          "feedback",
          "stats",
          "shortcuts",
          "tileview",
          "videobackgroundblur",
          "download",
          "help",
          "mute-everyone",
          "security"
        ]
      }}
      userInfo={{ displayName: callerId }}
      onApiReady={handleApiReady}
      onReadyToClose={handleReadyToClose}
      getIFrameRef={handleIFrameRef}
    />
  );
};

export default JitsiCallComponent;
