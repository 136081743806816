import { useGetAllDevicesQuery } from "@/store/features/devices/deviceApiSlice";
import { useEffect, useState } from "react";

const useGetAllDevices = () => {
  const [formattedData, setFormattedData] = useState({
    bluetooth: {
      title: "Bluetooth",
      devices: [],
    },
    cellular: {
      title: "Cellular",
      devices: [],
    },
    manual: {
      title: "Manual",
      devices: [],
    },
  });
  const { data, isLoading, isSuccess, isError, error } = useGetAllDevicesQuery({
    pageNumber: 1,
    pageSize: 50,
  });

  /**
   * we're formatting data in this format for UI to render easily
   * { bluetooth: [], cellular: [], manual: [] }
   * bluetooth, cellular, manual are the keys of the object
   * each key has an array of devices
   */
  useEffect(() => {
    const formattedDataObject = {
      bluetooth: {
        title: "Bluetooth",
        devices: [],
      },
      cellular: {
        title: "Cellular",
        devices: [],
      },
      manual: {
        title: "Manual",
        devices: [],
      },
    };
    data?.forEach((item) => {
      if (item.deviceTypeId === 1) {
        formattedDataObject.bluetooth.devices.push(item);
      } else if (item.deviceTypeId === 2) {
        formattedDataObject.cellular.devices.push(item);
      } else if (item.deviceTypeId === 3) {
        formattedDataObject.manual.devices.push(item);
      }
    });
    setFormattedData(formattedDataObject);
  }, [data]);

  return { data, formattedData, isLoading, isSuccess, isError, error };
};

export default useGetAllDevices;
