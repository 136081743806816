import { Card, Statistic } from "antd";
import styled from "styled-components";

const StyledCard = styled(Card)`
  margin-bottom: 16px;
`;

const StatisticCard = ({ title, value, icon, color }) => {
  return (
    <StyledCard bordered={false}>
      <Statistic
        title={title}
        value={value}
        valueStyle={{ color }}
        prefix={icon}
      />
    </StyledCard>
  );
};

export default StatisticCard;
