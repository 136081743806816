import { Form, Radio, Space } from "antd";
import {
  StyledContainer,
  StyledForm,
  StyledParagraph,
  StyledTitle,
} from "./Style";
const SmsReminder = () => {
  return (
    <StyledContainer>
      <StyledTitle level={4}>SMS Reminder Settings</StyledTitle>
      <StyledParagraph>
        Please select the phone number to be visible during SMS and Audio Call
        to patient
      </StyledParagraph>
      <StyledForm layout="vertical">
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <Form.Item
            label="Do you want to enable daily SMS reminder for your patient"
            name="dailyReminder"
          >
            <Radio.Group>
              <Radio value="yes">Yes</Radio>
              <Radio value="no">No</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Do you want to enable New Patients Sign-Up SMS for your Patients"
            name="newPatientSignup"
          >
            <Radio.Group>
              <Radio value="yes">Yes</Radio>
              <Radio value="no">No</Radio>
            </Radio.Group>
          </Form.Item>
        </Space>
      </StyledForm>
    </StyledContainer>
  );
};

export default SmsReminder;
