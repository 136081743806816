// Import necessary components and utilities
import { Layout } from 'antd' // Ant Design's Layout component
import styled from 'styled-components' // Utility for creating styled components
import logo from '../../../assets/images/logo.svg' // Import the logo image

// Destructure the Sider component from Ant Design's Layout
const { Sider } = Layout

// Create a styled version of Ant Design's Sider component
export const StyledSider = styled(Sider)`
  // Position the sidebar fixed on the left side of the screen
  position: fixed !important;
  left: 0;
  top: 0;
  bottom: 0;
  overflow: hidden !important;

  // Style the children of the Sider component
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    height: 100vh; // Full viewport height
  }
`

// Style the main content area of the sidebar
export const SidebarContent = styled.div`
  flex: 1; // Take up all available space
  overflow-y: auto; // Allow vertical scrolling

  // Customize the scrollbar appearance
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #001529;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f0f2f5;
  }
`

// Style the overall layout of the sidebar content
export const ContentLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`

// Style the wrapper for the main menu items
export const MenuWrapper = styled.div`
  flex-grow: 1; // Take up all available space
  overflow-y: auto; // Allow vertical scrolling
  margin-top: 24px;
`

// Style the bottom section of the sidebar
export const BottomSection = styled.div`
  margin-top: auto; // Push to the bottom
  padding-bottom: 48px; // Space for collapse button
`

// Style the wrapper for the help icon
export const HelpIconWrapper = styled.div`
  padding: 16px 0;
  text-align: center;
  color: white;
  cursor: pointer;
`

// Style for secondary menu items (e.g., Help, Settings)
const SecondaryMenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0 24px;
  margin: 4px 0;
  height: 40px;
  cursor: pointer;
  transition: background-color 0.3s;
  color: rgba(255, 255, 255, 0.65);

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .anticon {
    min-width: 14px;
    margin-right: 10px;
    font-size: 16px;
  }

  .ant-menu-title-content {
    color: rgba(255, 255, 255, 0.65);
  }
`

// Custom component for secondary menu items
export const CustomMenuItem = (props) => {
  const { icon, label, collapsed } = props
  return (
    <SecondaryMenuItem>
      {icon}
      {!collapsed && <span className="ant-menu-title-content">{label}</span>}
    </SecondaryMenuItem>
  )
}

// Style the container for the logo
const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  padding: 0 24px;
  background-color: #001529;
  color: white;
`

// Style the text part of the logo
const StyledText = styled.h3`
  font-family: 'Arial', sans-serif;
  font-size: 24px;
  font-weight: bold;
  color: #0088cc;
  margin: 0;
  padding: 0;

  span {
    color: #20b2aa;
  }
`

// Custom component for the logo
export const Logo = (props) => (
  <LogoContainer>
    <img src={logo} alt="logo" width={50} height={50} />
    {!props.collapsed && (
      <StyledText>
        Med<span>Kick</span>
      </StyledText>
    )}
  </LogoContainer>
)
