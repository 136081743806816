import { useState, useEffect, useCallback } from "react";
import styled from "styled-components";

const TypewriterContainer = styled.div`
  font-family: monospace;
  white-space: pre-wrap;
`;

const TypewriterEffect = ({ children, speed = 50 }) => {
  const [text, setText] = useState("");
  const content = typeof children === "string" ? children : "";

  const typeWriter = useCallback(() => {
    let i = 0;
    setText("");
    const timer = setInterval(() => {
      if (i < content.length - 1) {
        setText((prev) => prev + content[i]);
        i++;
      } else {
        clearInterval(timer);
      }
    }, speed);

    return () => clearInterval(timer);
  }, [content, speed]);

  useEffect(() => {
    const cleanup = typeWriter();
    return cleanup;
  }, [typeWriter]);

  return <TypewriterContainer>{text}</TypewriterContainer>;
};

export default TypewriterEffect;
