import { Button, Form, Row, Col, Space, Input } from "antd";
import { StyledFormContainer, StyledFormTitle } from "./Style";
import { useGetPatientByMrnQuery } from "@/store/features/patient/patientApiSlice";
import DeviceOrderForm from "./DeviceOrderForm";
import { useState } from "react";
import AddPatientWithInfo from "./AddPatientWithInfo";
import BackButton from "@/components/ui/BackButton";
import useCreatePatientValidation from "@/hooks/validation/useCreatePatientValidation";

export default function CreateOrder() {
  const [mrn, setMrn] = useState(null);

  const {
    data: patient,
    isSuccess,
    isError
  } = useGetPatientByMrnQuery(mrn, { skip: !mrn });

  const onFinish = (values) => {
    setMrn(values.mrn);
  };

  const { validateMRN } = useCreatePatientValidation();

  return (
    <StyledFormContainer>
      <Space align="center" style={{ marginBottom: "1rem" }}>
        <BackButton />

        <StyledFormTitle>Create Order</StyledFormTitle>
      </Space>
      <Form name="createOrder" onFinish={onFinish} layout="vertical">
        {/* First Name,Middle Name,  Last Name */}
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  validator: validateMRN
                }
              ]}
              name="mrn"
              label="Patient MRN Required for Device Order"
            >
              <Input placeholder="Your MRN" />
            </Form.Item>
          </Col>
        </Row>
        <Button htmlType="submit">Submit</Button>
      </Form>

      {isError === true ? <AddPatientWithInfo /> : null}

      {isSuccess === true ? <DeviceOrderForm patientInfo={patient} /> : null}
    </StyledFormContainer>
  );
}
