import { EditOutlined } from "@ant-design/icons";
import { StyledContainer, StyledTable, StyledTitle } from "./Style";
const columns = [
  {
    title: "Display Name",
    dataIndex: "name",
    key: "name",
    width: 190,
  },
  {
    title: "Faculty Api",
    dataIndex: "api",
    key: "api",
  },
  {
    title: "Practise/Location Name",
    dataIndex: "location",
    key: "location",
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "City",
    dataIndex: "city",
    key: "city",
  },
  {
    title: "State",
    dataIndex: "state",
    key: "state",
  },
  {
    title: "Zip Code",
    dataIndex: "zipCode",
    key: "zipCode",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    render: () => <EditOutlined />,
  },
];

const data = [
  {
    key: "1",
    name: "Health Art One",
    api: "957357",
    location: "New York Area",
    address: "1500 Main St",
    city: "New York",
    state: "NY",
    zipCode: "10001",
  },
  // ... (rest of the data array)
];

const LocationManagement = () => {
  return (
    <StyledContainer>
      <StyledTitle level={4}>Location Management</StyledTitle>
      <StyledTable
        columns={columns}
        dataSource={data}
        pagination={{
          pageSize: 5,
          showSizeChanger: false,
          showQuickJumper: true,
        }}
      />
    </StyledContainer>
  );
};

export default LocationManagement;
