import { Button, Table } from "antd";
import { StyledFormContainer } from "./styles";
import { Link, useParams } from "react-router-dom";
import { useGetDevicesByPatientIdQuery } from "@/store/features/patientDetails/patientDetailsApiSlice";
import dayjs from "dayjs";
const PatientDeviceDetails = () => {
  const { patientId } = useParams();
  const { data, isLoading } = useGetDevicesByPatientIdQuery({
    pageNumber: 1,
    pageSize: 10,
    patientId: patientId,
  });
  const columns = [
    {
      title: (
        <span style={{ fontWeight: "bold", color: "#1677ff" }}>
          Device Name
        </span>
      ),
      dataIndex: "deviceName",
      key: "deviceName",
    },
    {
      title: (
        <span style={{ fontWeight: "bold", color: "#1677ff" }}>
          Device Type
        </span>
      ),
      dataIndex: "deviceType",
      key: "deviceType",
    },
    {
      title: (
        <span style={{ fontWeight: "bold", color: "#1677ff" }}>
          Assigned Date
        </span>
      ),
      dataIndex: "assignedDate",
      key: "assignedDate",
      render: (date) => <span> {dayjs(date).format("DD-MMM-YYYY")}</span>,
    },
    {
      title: (
        <span style={{ fontWeight: "bold", color: "#1677ff" }}>
          First Transmission Date
        </span>
      ),
      dataIndex: "transmissionDate",
      key: "transmissionDate",
      render: (date) => <span> {dayjs(date).format("DD-MMM-YYYY")}</span>,
    },
  ];

  return (
    <div
      className="MainContainer"
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        paddingRight: "12px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          width: "100%",
          alignItems: "right",
          marginTop: "23px",
        }}
      >
        <Link to={`/patients/add/${patientId}/provided-devices`}>
          <Button
            type="primary"
            style={{ padding: "7px", borderRadius: "12px" }}
          >
            Add New Device
          </Button>
        </Link>
      </div>

      {/* Table */}

      <StyledFormContainer>
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={data}
          pagination={false}
          style={{ width: "100%" }}
        />
      </StyledFormContainer>
    </div>
  );
};

export default PatientDeviceDetails;
