import { StyledMainContainer, StyledCheckBoxContainer } from './Style'
import { Typography, Select, Input, Checkbox } from 'antd'
const onChange = (checkedValues) => {
  console.log('checked = ', checkedValues)
}

const options = [
  {
    label: 'Has Allergy',
    value: 'yes',
  },
  {
    label: 'Does Not have allergy',
    value: 'no',
  },
]

export default function FormContent() {
  return (
    <StyledMainContainer>
      <h3>
        1 OF 1 <span style={{ color: 'gray' }}>IN THE Allergies SECTION</span>
      </h3>
      <h1>Do you have any allergies?</h1>

      <StyledCheckBoxContainer>
        <Checkbox.Group
          options={options}
          onChange={onChange}
          style={{ display: 'flex', flexDirection: 'column', gap: '13px' }} // Ensure vertical alignment
        />
      </StyledCheckBoxContainer>
    </StyledMainContainer>
  )
}
