import { Button, Table, Tabs } from "antd";
import styled from "styled-components";

export const StyledMainConatiner = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.3;
  align-items: center;
  border-radius: "15px";
  background-color: #fff;
`;
export const StyledUpperDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;
export const StyledCoverPhoto = styled.div``;

export const StyledProfileDiv = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: -105px;
`;

export const StyledProfileEmailDiv = styled.div`
  display: flex;
  justify-content: center;
  margin: "10px auto";
  padding-bottom: 20px;
`;

export const StyldLowerDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const StyledFormContainer = styled.div`
  diplay: flex;

  justify-content: center;

  align-items: center;

  flex-direction: column;

  width: 100%;

  flex: 1;

  background-color: #fff;

  padding: 21px;

  border-radius: 15px;

  margin-left: 25px;
`;

export const StyledDivContainer = styled.div`
  justify-content: space-between;

  align-items: center;

  flex-direction: row;

  width: 100%;

  flex: 1;
`;

export const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: 16px;
  }
`;

export const ActionButton = styled(Button)`
  margin-bottom: 16px;
`;
