import {
  AudioOutlined,
  MailOutlined,
  PhoneOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Flex,
  Skeleton,
  Space,
  Spin,
  Tag,
  Typography,
} from "antd";
import dayjs from "dayjs";
import {
  StyledCoverPhoto,
  StyledMainConatiner,
  StyledProfileDiv,
  StyledProfileEmailDiv,
  StyledUpperDiv,
} from "./styles";
import { useParams } from "react-router-dom";
import {
  useGetHealthConditionsByPatientIdQuery,
  useGetPatientByIdQuery,
} from "@/store/features/patient/patientApiSlice";

export default function SidebarDashboardComponent() {
  const { patientId } = useParams();
  const { data, isLoading } = useGetPatientByIdQuery(patientId);
  const { data: healthConditionsData, isLoading: isHealthConditionsLoading } =
    useGetHealthConditionsByPatientIdQuery(patientId);
  return (
    <StyledMainConatiner>
      <Spin spinning={isLoading}>
        <StyledUpperDiv>
          <StyledCoverPhoto>
            <img
              src="https://images.unsplash.com/photo-1683064325134-3acfdef9c6d7?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt="Img Can't Load"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                borderTopRightRadius: "10px",
                borderTopLeftRadius: "10px",
              }}
            />
          </StyledCoverPhoto>

          <StyledProfileDiv>
            <div style={{ width: "160px", height: "160px" }}>
              <Avatar
                src={data?.photoUrl}
                alt={data?.firstName}
                shape="circle"
                size={160}
                style={{
                  borderRadius: "100px",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "10% 20%",
                }}
              />
              <Button
                type="primary"
                style={{
                  borderRadius: "25px",
                  position: "relative",
                  top: "-30px",
                  left: "15px",
                }}
              >
                #MRN: {data?.mrn}
              </Button>
            </div>
            <Space
              direction="vertical"
              style={{ textAlign: "center", marginTop: "10px" }}
            >
              <Typography.Title level={4}>
                {data?.firstName} {data?.middleName} {data?.lastName}
              </Typography.Title>
              <Typography.Text type="secondary">
                (DOB: {dayjs(data?.dob).format("DD-MMM-YYYY")})
              </Typography.Text>
              {/* unsure about the next line what to show here  */}
              {/* <Typography.Text type="">
                Alcohol Abuse, Aliginitics Rigin
              </Typography.Text> */}
              <Skeleton
                active
                loading={isHealthConditionsLoading}
                paragraph={false}
              >
                <Flex gap={15} justify="center">
                  {healthConditionsData?.[0]?.hasActivityRPM && (
                    <Tag color="green">RPM</Tag>
                  )}
                  {healthConditionsData?.[0]?.hasActivityCCM && (
                    <Tag color="blue">CCM</Tag>
                  )}
                  {healthConditionsData?.[0]?.hasActivityPCM && (
                    <Tag color="yellow">PCM</Tag>
                  )}
                  {healthConditionsData?.[0]?.hasActivityRTM && (
                    <Tag color="cyan">RTM</Tag>
                  )}
                </Flex>
              </Skeleton>
            </Space>
          </StyledProfileDiv>
          <Flex
            gap={15}
            justify="center"
            align="center"
            vertical
            style={{ padding: "10px" }}
          >
            <div>
              {data?.email && (
                <Typography.Text style={{ padding: "10px" }}>
                  <Flex gap={10} align="center">
                    <MailOutlined size={50} color="#1677ff" />{" "}
                    <span>{data?.email}</span>
                  </Flex>
                </Typography.Text>
              )}
            </div>
            <div>
              {data?.phone && (
                <Typography.Text style={{ padding: "10px" }}>
                  <Flex gap={10} align="center">
                    <PhoneOutlined rotate={90} size={50} color="#1677ff" />{" "}
                    {data?.phone}
                  </Flex>
                </Typography.Text>
              )}
            </div>
          </Flex>
          <StyledProfileEmailDiv>
            <Space>
              <Button
                style={{
                  backgroundColor: "orange",
                  color: "white",
                  borderRadius: "15px",
                }}
              >
                {" "}
                <VideoCameraOutlined
                  style={{
                    backgroundColor: "white",
                    color: "orange",
                    padding: "3px",
                    borderRadius: "14px",
                  }}
                />{" "}
                Video Call
              </Button>
              <Button
                style={{
                  backgroundColor: "rgba(0,255,0,0.7)",
                  color: "white",
                  borderRadius: "15px",
                }}
              >
                <AudioOutlined
                  style={{
                    backgroundColor: "white",
                    color: "rgba(0,255,0,0.7)",
                    padding: "3px",
                    borderRadius: "14px",
                  }}
                />{" "}
                Audio Call
              </Button>
            </Space>
          </StyledProfileEmailDiv>
        </StyledUpperDiv>
      </Spin>
    </StyledMainConatiner>
  );
}
