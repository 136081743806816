import styled from 'styled-components'

export const StyledMainContainer = styled.div`
  display: flex;

  flex-direction: column;

  padding: 9px;

  background-color: #fff;

  width: 90%;

  justify-content: center;

  padding-top: 15px;

  padding-bottom: 15px;
`
export const StyledDivContainer = styled.div`
  display: flex;

  flex-direction: row;
`

export const StyledFooterContainer = styled.div`
  display: flex;

  flex-direction: row;

  justify-content: end;
`
