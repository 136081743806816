import { EditTwoTone } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
const EditPatientDetails = (props) => {
  const { patientId } = props;
  const navigate = useNavigate();
  return (
    <Tooltip title="Edit Patient Details">
      <Button
        icon={<EditTwoTone />}
        onClick={() => navigate(`/patients/add/${patientId}/details`)}
      />
    </Tooltip>
  );
};

export default EditPatientDetails;
