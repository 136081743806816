import { GET_PATIENT_DASHBOARD_DATA } from "@/constants/apiDefinition";
import { apiSlice } from "@/store/features/api/apiSlice";

export const dashboardApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDashboardData: builder.query({
      query: ({ pageNumber = 1, pageSize = 50 }) => ({
        url: GET_PATIENT_DASHBOARD_DATA,
        method: "GET",
        params: { pageNumber, pageSize },
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Dashboard", id })),
              { type: "Dashboard", id: "LIST" },
            ]
          : [{ type: "Dashboard", id: "LIST" }],
    }),
  }),
});

export const { useGetDashboardDataQuery } = dashboardApi;
