import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Flex, Form, Input, Typography, Button, Spin } from "antd";
import { setCurrentStep } from "@/store/features/ccm/ccmFormSlice";
import ErrorAlert from "@/components/ui/errorAlert";
import StepControls from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/StepControls";
import {
  useGetCCMAdditionalInfoQuery,
  useCreateCCMAdditionalNotesMutation,
  useUpdateCCMAdditionalNotesMutation,
} from "@/store/features/ccm/ccmApiSlice";

const PsychoNeuroTestingForm = ({ multiStep = false }) => {
  const [form] = Form.useForm();
  const { patientId } = useParams();
  const dispatch = useDispatch();
  const { currentStep } = useSelector((state) => state.ccmForm);
  const [isEditing, setIsEditing] = useState(false);

  const { data: psychoNeuroInfo, isLoading: isPsychoNeuroLoading } =
    useGetCCMAdditionalInfoQuery({
      patientId,
      pageNumber: 1,
      pageSize: 1,
      ccmAdditionalInfoTypeId: 5,
    });
  const [createPsychoNeuroInfo, { isLoading: isCreating }] =
    useCreateCCMAdditionalNotesMutation();
  const [updatePsychoNeuroInfo, { isLoading: isUpdating }] =
    useUpdateCCMAdditionalNotesMutation();

  useEffect(() => {
    if (psychoNeuroInfo && psychoNeuroInfo.length > 0) {
      form.setFieldsValue({ description: psychoNeuroInfo[0].description });
      setIsEditing(false);
    } else {
      setIsEditing(multiStep);
    }
  }, [psychoNeuroInfo, form, multiStep]);

  const handleFinish = async () => {
    const values = form.getFieldsValue(true);
    const payload = { ...values, ccmAdditionalInfoTypeId: 5, patientId };
    if (psychoNeuroInfo && psychoNeuroInfo.length > 0) {
      await updatePsychoNeuroInfo({
        ...payload,
        ccmAdditionalInfoId: psychoNeuroInfo[0].ccmAdditionalInfoId,
        isInitial: false,
      });
    } else {
      await createPsychoNeuroInfo({ ...payload, isInitial: true });
    }
    setIsEditing(false);
    if (multiStep) {
      dispatch(setCurrentStep(currentStep + 1));
    }
  };

  const handleCancel = () => {
    if (psychoNeuroInfo && psychoNeuroInfo.length > 0) {
      form.setFieldsValue({ description: psychoNeuroInfo[0].description });
    } else {
      form.resetFields();
    }
    setIsEditing(false);
  };

  if (multiStep && currentStep !== 8) return null;

  return (
    <Spin spinning={isPsychoNeuroLoading}>
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <ErrorAlert />
        <Flex vertical gap={10}>
          <Typography.Title level={5}>
            Has the patient gone through Psychological and Neuro-Psychological
            Testing?
          </Typography.Title>

          <Form.Item name="description">
            <Input.TextArea
              placeholder="Please describe in Details"
              rows={10}
              disabled={multiStep ? false : !isEditing}
            />
          </Form.Item>
        </Flex>

        {multiStep ? (
          <StepControls
            isLoading={isCreating || isUpdating}
            onNextClick={form.submit}
          />
        ) : isEditing ? (
          <Flex justify="flex-end" gap={10}>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button
              type="primary"
              onClick={form.submit}
              loading={isCreating || isUpdating}
            >
              {psychoNeuroInfo && psychoNeuroInfo.length > 0
                ? "Update"
                : "Save"}
            </Button>
          </Flex>
        ) : (
          <Button onClick={() => setIsEditing(true)}>Edit</Button>
        )}
      </Form>
    </Spin>
  );
};

export default PsychoNeuroTestingForm;
