import {
  CloseOutlined,
  CustomerServiceFilled,
  EditOutlined,
} from '@ant-design/icons'
import {
  Button,
  Space,
  Table,
  Radio,
  Row,
  Col,
  Flex,
  Typography,
  Form,
  DatePicker,
  Input,
  Checkbox,
  Divider,
} from 'antd'
import React from 'react'
import { StyledDivContainer, StyledFormContainer } from './Style'

const data = [
  {
    key: '1',
    vital: 'Temperature',
    values: '104',
    notification: (
      <span
        style={{
          backgroundColor: 'pink',
          padding: '5px 10px 5px 10px',
          borderRadius: '9px',
        }}
      >
        critical
      </span>
    ),
    date: 'June 09 2023',
    case: <Checkbox />,
    notes: <Input type="text" style={{ width: '150px', padding: '9px' }} />,
  },
  {
    key: '2',
    vital: 'Glucose',
    values: '304',
    notification: (
      <span
        style={{
          backgroundColor: 'pink',
          padding: '5px 10px 5px 10px',
          borderRadius: '9px',
        }}
      >
        critical
      </span>
    ),
    date: 'April 04 2023',
    case: <Checkbox />,
    notes: <Input type="text" style={{ width: '150px', padding: '9px' }} />,
  },
  {
    key: '3',
    vital: 'Vitamin-C',
    values: '134',
    notification: (
      <span
        style={{
          backgroundColor: 'pink',
          padding: '5px 10px 5px 10px',
          borderRadius: '9px',
        }}
      >
        critical
      </span>
    ),
    date: 'May 05 2023',
    case: <Checkbox />,
    notes: <Input type="text" style={{ width: '150px', padding: '9px' }} />,
  },
  {
    key: '4',
    vital: 'Temperature',
    values: '104',
    notification: (
      <span
        style={{
          backgroundColor: 'orange',
          padding: '5px 10px 5px 10px',
          borderRadius: '9px',
        }}
      >
        critical
      </span>
    ),
    date: 'June 09 2023',
    case: <Checkbox />,
    notes: <Input type="text" style={{ width: '150px', padding: '9px' }} />,
  },
  {
    key: '5',
    vital: 'Temperature',
    values: '104',
    notification: (
      <span
        style={{
          backgroundColor: 'pink',
          padding: '5px 10px 5px 10px',
          borderRadius: '9px',
        }}
      >
        critical
      </span>
    ),
    date: 'June 09 2023',
    case: <Checkbox />,
    notes: <Input type="text" style={{ width: '150px', padding: '9px' }} />,
  },
]

const NotificationTable = () => {
  const columns = [
    {
      title: 'Vitals',
      dataIndex: 'vital',
      key: 'vitals',
    },
    {
      title: 'Values',
      dataIndex: 'values',
      key: 'values',
    },
    {
      title: 'Notifications',
      dataIndex: 'notification',
      key: 'notification',
    },
    {
      title: 'Recorded Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Case Handled',
      dataIndex: 'case',
      key: 'case',
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
    },
  ]

  return (
    <div
      className="MainContainer"
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'center',
        paddingRight: '12px',
      }}
    >
      {/* Table */}

      <StyledFormContainer>
        {/* Heading Content */}

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography.Title level={3}>
            Notifications-Ritika Negi
          </Typography.Title>
          <Typography.Text>
            Timer:8 Sec{' '}
            <span
              style={{
                fontSize: '21px',
                padding: '0px 4px 0px 4px',
                cursor: 'pointer',
              }}
            >
              <CloseOutlined />
            </span>{' '}
          </Typography.Text>
        </div>

        {/* Table */}

        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{ y: 280, x: 140 }}
          style={{
            marginTop: '25px',
            borderTop: '1px solid black',
            borderRight: '1px solid black',
          }}
        />

        {/* Buttons  */}

        <Space
          direction="horizontal"
          style={{
            marginTop: '20px',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button>Cancel</Button>
          <Button type="primary">Save</Button>
        </Space>
      </StyledFormContainer>
    </div>
  )
}

export default NotificationTable
