import NotesIcon from "@/assets/icons/NotesIcon";
import { Button, Tooltip } from "antd";
const QuickNote = () => {
  return (
    <Tooltip title="Quick Note">
      <Button icon={<NotesIcon />} />
    </Tooltip>
  );
};

export default QuickNote;
