import { Button, Form, Select, Row, Col, Divider, DatePicker } from 'antd'
import { StyledFormContainer, StyledFormTitle } from './Style'
const { Option } = Select

export default function ReportsFrom() {
  const onFinish = (values) => {
    console.log('Received values of form: ', values)
  }

  return (
    <StyledFormContainer>
      <StyledFormTitle level={3} style={{ marginBottom: '22px' }}>
        Select Data To Generate Report
      </StyledFormTitle>
      <Form
        name="reportsForm"
        onFinish={onFinish}
        layout="vertical"
        requiredMark="optional"
      >
        {/* {Report Type type} */}
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="reportType"
              label="Report Type"
              rules={[
                {
                  required: true,
                  message: 'Please select the Group Type!',
                },
              ]}
            >
              <Select placeholder="Billing Report">
                <Option value="billing">Billing Report</Option>
                <Option value="patient">Patient Compliance Report</Option>
                <Option value="orders">Orders</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="month"
              label="Select Month"
              rules={[
                {
                  required: true,
                  message: 'Please select Month!',
                },
              ]}
            >
              <DatePicker
                picker="month"
                style={{ width: '100%' }}
                placeholder="Select Month"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="location"
              label="Location(s)"
              rules={[
                {
                  required: true,
                  message: 'Please select Location!',
                },
              ]}
            >
              <Select placeholder="pakistan">
                <Option value="pakistan">Pakistan</Option>
                <Option value="bangladesh">Bangladesh</Option>
                <Option value="America">America</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {/* Divider */}

        <Divider />

        {/* Cancel & Generate Button */}
        <Row gutter={11}>
          <Col span={3}>
            <Form.Item>
              <Button
                style={{ borderRadius: '7px', padding: '2px 17px' }}
                htmlType="submit"
              >
                Cancel
              </Button>
            </Form.Item>
          </Col>
          <Form.Item>
            <Button
              type="primary"
              style={{ borderRadius: '7px', padding: '2px 17px' }}
              htmlType="submit"
            >
              Generate
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </StyledFormContainer>
  )
}
