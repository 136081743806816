import { Space } from "antd";
import EditPatientDetails from "./EditPatientDetails";
import Messages from "./Messages";
import QuickNote from "./QuickNote";
import Report from "./Report";
import Tasks from "./Tasks";

const Actions = ({ record }) => {
  return (
    <Space.Compact align="center">
      <QuickNote />
      <Tasks />
      <Messages />
      <Report />
      <EditPatientDetails patientId={record.patientId} />
    </Space.Compact>
  );
};

export default Actions;
