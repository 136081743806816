import Icon from '@ant-design/icons'



const CustomIcon = () => {
    return(
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      enableBackground="new 0 0 512 512"
      viewBox="0 0 512 512"
    >
      <path
        fill="#231f20"
        d="M297.941 48H96v416h320V166.059z"
        data-original="#231f20"
      ></path>
      <path
        fill="#fff"
        d="M392 440H120V72h168l104 104z"
        data-original="#ffffff"
      ></path>
      <path
        fill="#ed1b2e"
        d="M288 176V72l104 104z"
        data-original="#ed1b2e"
      ></path>
      <path
        fill="#e6e7e8"
        d="M288 176l104 104V176z"
        data-original="#e6e7e8"
      ></path>
      <g fillRule="evenodd" clipRule="evenodd">
        <g fill="#e6e7e8">
          <path
            d="M189.897 327.433c-22.516 16.203-38.008 39.22-31.896 42.815l-5.342-2.591c-3.09-3.64 3.932-23.158 37.238-40.224zm169.143-18.167c11.532-23.647-83.328-20.099-152.64 10.906 53.95-20.03 153.793-28.655 152.64-10.906z"
            data-original="#e6e7e8"
          ></path>
          <path
            d="M251.732 183.314c2.59-13.137-4.072-12.673-5.939-12.673l-4.207-.033c-2.32 0-4.403 1.794-5.415 5.441-6.575 23.641 5.356 83.652 38.829 111.412 29.556 24.511 81.013 35.726 83.084 23.877-10.694 4.948-50.861-7.505-77.866-28.228-31.911-25.246-45.557-87.506-38.684-106.975.683-1.94 2.345-4.208 3.082-4.716 2.868 1.382 6.335 4.779 7.116 11.895z"
            data-original="#e6e7e8"
          ></path>
          <path
            d="M251.172 187.104c-2.938 13.94-4.213 46.874-32.113 101.013-29.966 58.148-51.84 85.263-66.4 79.541l5.205 2.572c11.406 5.614 32.403-12.85 67.249-77.541 26.839-49.823 25.387-75.468 26.059-105.585z"
            data-original="#e6e7e8"
          ></path>
        </g>
        <g fill="#ed1b2e">
          <path
            d="M189.897 324.647c-22.516 16.203-38.008 39.22-31.896 42.815l-5.342-2.591c-3.09-3.639 3.932-23.157 37.238-40.224zM359.04 306.48c11.532-23.647-83.328-20.099-152.64 10.906 53.95-20.03 153.793-28.654 152.64-10.906z"
            data-original="#ed1b2e"
          ></path>
          <path
            d="M251.732 180.528c2.59-13.137-4.072-12.673-5.939-12.673l-4.207-.033c-2.32 0-4.403 1.794-5.415 5.441-6.575 23.641 5.356 83.652 38.829 111.412 29.556 24.511 81.013 35.726 83.084 23.877-10.694 4.948-50.861-7.505-77.866-28.228-31.911-25.246-45.557-87.506-38.684-106.975.683-1.94 2.345-4.208 3.082-4.716 2.868 1.382 6.335 4.779 7.116 11.895z"
            data-original="#ed1b2e"
          ></path>
          <path
            d="M251.172 184.318c-2.938 13.94-4.213 46.874-32.113 101.013-29.966 58.148-51.84 85.263-66.4 79.541l5.205 2.572c11.406 5.614 32.403-12.85 67.249-77.541 26.839-49.823 25.387-75.467 26.059-105.585z"
            data-original="#ed1b2e"
          ></path>
        </g>
      </g>
    </svg>
    );
}



const PdfIcon = (props) => <Icon component={CustomIcon} {...props} />

export default PdfIcon;