import { Table } from "antd";
import columns from "./columns";
import useGetAllPatients from "@/hooks/dashboard/useGetAllPatients";
import { useState } from "react";

const TableContent = () => {
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  });
  const { patientList, isLoading, totalCount } = useGetAllPatients({
    pageNumber: pagination.pageNumber,
    pageSize: pagination.pageSize,
  });

  const handlePagination = (pageNumber) => {
    setPagination((prev) => {
      return { ...prev, pageNumber: pageNumber };
    });
  };
  return (
    <Table
      columns={columns}
      key="patientId"
      dataSource={patientList}
      style={{ width: "100%" }}
      pagination={{
        pageSize: pagination.pageSize,
        current: pagination.pageNumber,
        total: totalCount,
        onChange: handlePagination,
      }}
      loading={isLoading}
    />
  );
};
export default TableContent;
