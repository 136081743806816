import EmptyState from "@/components/ui/empty/Empty";
import useGetAllPatients from "@/hooks/dashboard/useGetAllPatients";
import { Col, Flex, Row, Typography } from "antd";
import MonitoringSelector from "./components/MonitoringSelector";
import PatientCard from "./components/PatientCard";
import AISearchComponent from "./components/Searchbar";
import { useState } from "react";
import FilterForm from "@/Pages/Dashboard/filter";
import CustomPagination from "@/components/shared/pagination";
import StatisticCard from "@/Pages/Dashboard/components/StatisticsCard";
import {
  AlertOutlined,
  ClockCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useGetDashboardDataQuery } from "@/store/features/dashboard/dashboardApiSlice";

const Dashboard = () => {
  const [showFilters, setShowFilters] = useState(false);
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  });
  const { totalCount } = useGetAllPatients({
    pageNumber: pagination.pageNumber,
    pageSize: pagination.pageSize,
  });

  const { data: dashboardData, isLoading: isDashboardLoading } =
    useGetDashboardDataQuery({
      pageNumber: pagination.pageNumber,
      pageSize: pagination.pageSize,
    });
  const handlePagination = (pageNumber) => {
    setPagination((prev) => {
      return { ...prev, pageNumber: pageNumber };
    });
  };
  return (
    <Flex vertical style={{ minHeight: "100vh" }}>
      <Row
        gutter={[16, 16]}
        justify="space-between"
        align="middle"
        style={{ margin: "20px 0px" }}
      >
        <Col xs={24} md={6}>
          <StatisticCard
            title="Total Patients"
            value={totalCount}
            icon={<UserOutlined />}
            color="black"
          />
        </Col>
        <Col xs={24} md={6}>
          <StatisticCard
            title="Total Time Tracked(m)"
            value={25000}
            icon={<ClockCircleOutlined />}
            color="black"
          />
        </Col>
        <Col xs={24} md={6}>
          <StatisticCard
            title="High BP Patients"
            value={260}
            icon={<AlertOutlined />}
            color="black"
          />
        </Col>
        <Col xs={24} md={6}>
          <StatisticCard
            title="High Risk Patients"
            value={20}
            icon={<AlertOutlined />}
            color="black"
          />
        </Col>
      </Row>
      <Row
        justify="space-between"
        align="middle"
        style={{ margin: "20px 0px" }}
      >
        <Col xs={24} md={8}>
          <Typography.Title level={3}>Patient List</Typography.Title>
        </Col>
        <Col xs={24} md={8}>
          <AISearchComponent onFilter={setShowFilters} />
        </Col>
        <Col xs={24} md={8}>
          <Flex justify="flex-end">
            <MonitoringSelector options={monitoringOptions} value={"rpm"} />
          </Flex>
        </Col>
      </Row>
      <div style={{ overflow: "auto" }}>
        {dashboardData?.length ? (
          <>
            {dashboardData?.map((patient) => (
              <PatientCard
                key={patient.id}
                patient={patient}
                loading={isDashboardLoading}
              />
            ))}
            <CustomPagination
              current={pagination.pageNumber}
              total={totalCount}
              pageSize={pagination.pageSize}
              onChange={handlePagination}
            />
          </>
        ) : (
          <EmptyState />
        )}
      </div>
      <FilterForm open={showFilters} setOpen={setShowFilters} />
    </Flex>
  );
};

export default Dashboard;

const monitoringOptions = [
  {
    label: "RPM",
    value: "rpm",
  },
  {
    label: "CCM",
    value: "ccm",
  },
  {
    label: "PCM",
    value: "pcm",
  },
  {
    label: "RTM",
    value: "rtm",
  },
];
