import {
  CREATE_ADD_USER_URL,
  CREATE_CAREGIVER_URL,
  CREATE_CCM_PCM_URL,
  CREATE_DEVICE_PATIENT_MAPPING_URL,
  CREATE_DEVICE_PROVIDER_URL,
  CREATE_HEALTH_CONDITION_URL,
  CREATE_PATIENT_SETTING_URL,
  CREATE_PATIENT_URL,
  CREATE_RPM_RTM_URL,
  GET_ALL_PATIENTS_URL,
  GET_HEALTH_CONDITION_BY_ID_URL,
  GET_PATIENT_BY_MRN_URL
} from "@/constants/apiDefinition";
import { apiSlice } from "@/store/features/api/apiSlice";

export const patientApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createPatient: builder.mutation({
      query: (data) => {
        const formData = new FormData();
        // Iterate over all fields in data
        Object.keys(data).forEach((key) => {
          if (key === "ImageFile") {
            formData.append("ImageFile", data[key]);
          } else {
            // Handle other form fields
            formData.append(key, data[key]);
          }
        });
        return {
          url: CREATE_PATIENT_URL,
          method: "POST",
          body: formData,
          formData: true,
          invalidatesTags: ["patientList"]
        };
      }
    }),

    getAllPatients: builder.query({
      query: (queryArgs) => {
        const {
          pageNumber = 1,
          pageSize = 10,
          searchTerm = ""
        } = queryArgs || {};
        return {
          url: GET_ALL_PATIENTS_URL,
          method: "GET",
          params: { pageNumber, pageSize, searchTerm }
        };
      },
      transformResponse: (response, meta) => {
        // Return an object containing both the response data and headers
        return { data: response, headers: meta.response.headers };
      },
      providesTags: ["patientList"]
    }),
    getPatientById: builder.query({
      query: (id) => ({
        url: `${GET_ALL_PATIENTS_URL}/${id}`,
        method: "GET"
      }),
      providesTags: (result, error, id) => [{ type: "Patient", id }]
    }),
    updatePatient: builder.mutation({
      query: (patientData) => {
        const formData = new FormData();
        // Iterate over all fields in data
        Object.keys(patientData).forEach((key) => {
          if (key === "ImageFile") {
            formData.append("ImageFile", patientData[key]);
          } else if (patientData[key]) {
            // Handle other form fields
            formData.append(key, patientData[key]);
          }
        });
        return {
          url: GET_ALL_PATIENTS_URL,
          method: "PUT",
          body: formData,
          formData: true
        };
      },
      // Invalidate the specific patient and the list
      invalidatesTags: (result, error, patientData) => [
        { type: "Patient", id: patientData.patientId },
        { type: "patientList" }
      ]
    }),
    // CareGiverInfo
    createCareGiver: builder.mutation({
      query: (data) => {
        console.log("data", data);
        return {
          url: CREATE_CAREGIVER_URL,
          method: "POST",
          body: data
        };
      }
    }),
    // RPM
    createRpmSetup: builder.mutation({
      query: (data) => {
        return {
          url: CREATE_RPM_RTM_URL,
          method: "POST",
          body: data
        };
      }
    }),
    // RPM
    createCcmSetup: builder.mutation({
      query: (data) => {
        return {
          url: CREATE_CCM_PCM_URL,
          method: "POST",
          body: data
        };
      }
    }),
    // Health Condition
    createHealthCondition: builder.mutation({
      query: (data) => {
        return {
          url: CREATE_HEALTH_CONDITION_URL,
          method: "POST",
          body: data
        };
      }
    }),
    // DeviceProvider
    createDeviceProvider: builder.mutation({
      query: (data) => {
        return {
          url: CREATE_DEVICE_PROVIDER_URL,
          method: "POST",
          body: data
        };
      }
    }),
    // Patient Setting
    createPatientSetting: builder.mutation({
      query: (data) => {
        return {
          url: CREATE_PATIENT_SETTING_URL,
          method: "POST",
          body: data
        };
      }
    }),
    // Provided Devices
    createProvidedDevices: builder.mutation({
      query: (data) => {
        return {
          url: CREATE_DEVICE_PATIENT_MAPPING_URL,
          method: "POST",
          body: data
        };
      }
    }),

    // Add User
    createAddUser: builder.mutation({
      query: (data) => {
        return {
          url: CREATE_ADD_USER_URL,
          method: "POST",
          body: data
        };
      }
    }),
    //get health conditions
    getHealthConditionsByPatientId: builder.query({
      query: (patientId) => {
        return {
          url: GET_HEALTH_CONDITION_BY_ID_URL,
          method: "GET",
          params: { patientId }
        };
      }
    }),

    // get patient by mrn
    getPatientByMrn: builder.query({
      query: (mrn) => ({
        url: `${GET_PATIENT_BY_MRN_URL}${mrn}`,
        method: "GET"
      }),
      providesTags: (result, error, id) => [{ type: "Patient", id }]
    })
  })
});
export const {
  useCreatePatientMutation,
  useCreateCareGiverMutation,
  useCreateRpmSetupMutation,
  useGetAllPatientsQuery,
  useCreateHealthConditionMutation,
  useCreateDeviceProviderMutation,
  useCreatePatientSettingMutation,
  useCreateAddUserMutation,
  useCreateCcmSetupMutation,
  useCreateProvidedDevicesMutation,
  useGetPatientByIdQuery,
  useUpdatePatientMutation,
  useGetHealthConditionsByPatientIdQuery,
  useGetPatientByMrnQuery
} = patientApi;
