import {
  CloseOutlined,
  SearchOutlined,
  SendOutlined,
  UploadOutlined
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Flex,
  Input,
  List,
  Popover,
  Typography,
  Upload
} from "antd";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { Fragment, useState } from "react";
import {
  Container,
  ChatContainer,
  ChatHeader,
  LastMessageTime,
  MessageBubble,
  MessageInputWrapper,
  MessageList,
  Sidebar,
  StyledList
} from "./Style";
import CallInterface from "./CallInterface";
dayjs.extend(relativeTime);

const { Text } = Typography;

const MessagingUI = () => {
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [messages, setMessages] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [fileList, setFileList] = useState([]);

  const people = [
    {
      id: 1,
      name: "Dr. Smith",
      avatar: "S",
      lastMessage: "Your test results are ready.",
      lastMessageTime: dayjs().subtract(2, "hour")
    },
    {
      id: 2,
      name: "Dr. Johnson",
      avatar: "J",
      lastMessage: "How are you feeling today?",
      lastMessageTime: dayjs().subtract(1, "day")
    },
    {
      id: 3,
      name: "Dr. Williams",
      avatar: "W",
      lastMessage: "Please schedule a follow-up.",
      lastMessageTime: dayjs().subtract(3, "day")
    }
  ];

  const handlePersonSelect = (person) => {
    setSelectedPerson(person);
    // In a real app, you would fetch messages for this person here
    setMessages([
      {
        id: 1,
        content: `Hello, I'm ${person.name}. How can I help you today?`,
        isUser: false
      }
    ]);
  };

  const handleSend = () => {
    if (inputValue.trim() && selectedPerson) {
      const newMessage = {
        id: messages.length + 1,
        content: inputValue,
        isUser: true
      };
      setMessages([...messages, newMessage]);
      setInputValue("");
    }
  };

  const onSearchOpen = () => {
    console.log("search opened");
  };

  const popOverContent = (
    <Flex gap={"small"}>
      <Input
        value={searchText}
        placeholder="search within chat."
        onChange={(e) => setSearchText(e.target.value)}
        prefix={<SearchOutlined />}
      />
      <Button icon={<CloseOutlined />} onClick={() => setSearchText("")} />
    </Flex>
  );

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList
  };

  return (
    <Container>
      <Sidebar>
        <StyledList
          dataSource={people}
          renderItem={(person) => (
            <List.Item onClick={() => handlePersonSelect(person)}>
              <List.Item.Meta
                avatar={<Avatar>{person.avatar}</Avatar>}
                title={person.name}
                description={person.lastMessage}
              />
              <LastMessageTime>
                {person.lastMessageTime.fromNow()}
              </LastMessageTime>
            </List.Item>
          )}
        />
      </Sidebar>
      <ChatContainer>
        {selectedPerson ? (
          <Fragment>
            <ChatHeader>
              {/*left section of the header*/}
              <Flex align="center">
                <Avatar style={{ marginRight: 16 }}>
                  {selectedPerson.avatar}
                </Avatar>
                <Text strong>{selectedPerson.name}</Text>
              </Flex>

              {/*right section of the header*/}
              <Flex gap="middle">
                <CallInterface currentUserId={"1"} contactId={"2"} />

                <Popover title={popOverContent} placement="bottomLeft">
                  <Button
                    type="text"
                    icon={<SearchOutlined />}
                    content={popOverContent}
                    onClick={onSearchOpen}
                  />
                </Popover>
              </Flex>
            </ChatHeader>
            <MessageList>
              {messages.map((message) => (
                <MessageBubble key={message.id} isUser={message.isUser}>
                  {message.content}
                </MessageBubble>
              ))}
            </MessageList>
            <MessageInputWrapper>
              <Upload {...props}>
                <Button type="text" icon={<UploadOutlined />} />
              </Upload>
              <Input
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onPressEnter={handleSend}
                suffix={
                  <Button
                    type="primary"
                    icon={<SendOutlined />}
                    onClick={handleSend}
                  />
                }
                placeholder="Type your message here..."
              />
            </MessageInputWrapper>
          </Fragment>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%"
            }}
          >
            <Text>Select a person to start chatting</Text>
          </div>
        )}
      </ChatContainer>
    </Container>
  );
};

export default MessagingUI;
