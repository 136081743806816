import PdfIcon from "@/assets/icons/PdfIcon";
import { Button, Tooltip } from "antd";
const Report = () => {
  return (
    <Tooltip title="Report">
      <Button icon={<PdfIcon />} />
    </Tooltip>
  );
};

export default Report;
