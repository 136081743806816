import React from 'react'
import { Empty } from 'antd'
import styled from 'styled-components'

const FullScreenContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 300px);
  width: 100%;
`

const EmptyState = () => (
  <FullScreenContainer>
    <Empty />
  </FullScreenContainer>
)

export default EmptyState
