import { useEffect, useState } from "react";
import { Form, Typography, Flex, Button, Spin } from "antd";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import RichTextEditor from "@/components/shared/richTextEditor";
import ErrorAlert from "@/components/ui/errorAlert";
import StepControls from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/StepControls";
import { setCurrentStep } from "@/store/features/ccm/ccmFormSlice";
import {
  useGetCCMPatientHistoryQuery,
  useUpdatePatientHistoryMutation,
  useCreatePatientHistoryMutation,
} from "@/store/features/ccm/ccmApiSlice";

const PatientHistoryForm = ({ multiStep = false }) => {
  const [form] = Form.useForm();
  const { patientId } = useParams();
  const dispatch = useDispatch();
  const { currentStep } = useSelector((state) => state.ccmForm);
  const [isEditing, setIsEditing] = useState(false);

  const { data: patientHistory, isLoading: isLoadingHistory } =
    useGetCCMPatientHistoryQuery(patientId);
  const [updatePatientHistory, { isLoading: isUpdating }] =
    useUpdatePatientHistoryMutation();
  const [createPatientHistory, { isLoading: isCreating }] =
    useCreatePatientHistoryMutation();

  useEffect(() => {
    if (patientHistory && patientHistory.length > 0) {
      form.setFieldsValue({
        patientHistoryDescription: patientHistory[0].patientHistoryDescription,
      });
      setIsEditing(false);
    } else {
      setIsEditing(multiStep);
    }
  }, [patientHistory, form, multiStep]);

  const handleFinish = async (values) => {
    const payload = { ...values, patientId };
    if (patientHistory && patientHistory.length > 0) {
      await updatePatientHistory({
        ...payload,
        ccmPatientHistoryId: patientHistory[0].ccmPatientHistoryId,
        isInitial: false,
      });
    } else {
      await createPatientHistory({ ...payload, isInitial: true });
    }
    setIsEditing(false);
    if (multiStep) {
      dispatch(setCurrentStep(currentStep + 1));
    }
  };

  const handleCancel = () => {
    if (patientHistory && patientHistory.length > 0) {
      form.setFieldsValue({
        patientHistoryDescription: patientHistory[0].patientHistoryDescription,
      });
    } else {
      form.resetFields();
    }
    setIsEditing(false);
  };

  if (multiStep && currentStep !== 0) return null;

  return (
    <Form form={form} layout="vertical" onFinish={handleFinish}>
      <ErrorAlert />
      <Flex vertical gap={10}>
        <Typography.Title level={5} style={{ margin: "0px 15px" }}>
          Patient History
        </Typography.Title>

        <Spin spinning={isLoadingHistory}>
          <RichTextEditor
            form={form}
            keyName="patientHistoryDescription"
            initialValue={patientHistory?.[0]?.patientHistoryDescription || ""}
            sx={{ marginBottom: "30px" }}
            readOnly={multiStep ? false : !isEditing}
          />
        </Spin>
      </Flex>

      {multiStep ? null : isEditing ? (
        <Flex justify="flex-end" gap={10}>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isUpdating || isCreating}
          >
            {patientHistory && patientHistory.length > 0 ? "Update" : "Save"}
          </Button>
        </Flex>
      ) : (
        <Button onClick={() => setIsEditing(true)}>Edit</Button>
      )}

      {multiStep && (
        <StepControls isLoading={isCreating} onNextClick={form.submit} />
      )}
    </Form>
  );
};

export default PatientHistoryForm;
