import styled from "styled-components";
import { Typography, Form } from "antd";
const { Title, Paragraph } = Typography;

export const StyledContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.03);
  padding: 24px;
  border-radius: 8px;
  box-shadow:
    0 1px 3px #d9d9d9ee,
    0 1px 2px #d9d9d9ee;
`;

export const StyledTitle = styled(Title)`
  margin-bottom: 16px;
  @media (max-width: 576px) {
    text-align: center;
  }
`;

export const StyledParagraph = styled(Paragraph)`
  font-weight: 400;
  color: #666666;
  margin-bottom: 24px;
`;

export const StyledForm = styled(Form)`
  .ant-form-item-label > label {
    font-weight: 500;
  }
`;
