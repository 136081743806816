import { useGetAllPatientsQuery } from "@/store/features/patient/patientApiSlice";
import { useEffect, useState } from "react";

const useGetAllPatients = ({ pageNumber, pageSize }) => {
  const { isLoading, isSuccess, isError, data, error } = useGetAllPatientsQuery(
    { pageNumber: pageNumber, pageSize: pageSize }
  );
  const [patientList, setPatientList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  useEffect(() => {
    if (isSuccess && data) {
      setPatientList(data.data);
      const total = JSON.parse(data.headers.get("Pagination"))?.totalItems;
      setTotalCount(total);
    } else {
      setPatientList([]);
      setTotalCount(0);
    }
  }, [data, isSuccess]);

  return { isLoading, isSuccess, isError, patientList, totalCount, error };
};

export default useGetAllPatients;
