import { useGetAllTimeLogByPatientIdQuery } from "@/store/features/timelog/timelogApiSlice";
import { Button, Col, DatePicker, Form, Row, Table, Typography } from "antd";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { StyledFormContainer } from "./styles";

const PatientTimeLogDetails = () => {
  const { patientId } = useParams();
  const { data, isLoading } = useGetAllTimeLogByPatientIdQuery({
    pageNumber: 1,
    pageSize: 10,
    patientId: patientId,
  });
  const getTimeDiff = (record) => {
    if (!record?.startTime || !record?.endTime) {
      return "00:00:00";
    }

    const start = dayjs(record.startTime);
    const end = dayjs(record.endTime);
    const diffInSeconds = end.diff(start, "second");

    const hours = Math.floor(diffInSeconds / 3600);
    const minutes = Math.floor((diffInSeconds % 3600) / 60);
    const seconds = diffInSeconds % 60;

    return [hours, minutes, seconds]
      .map((v) => (v < 10 ? "0" + v : v))
      .join(":");
  };
  const columns = [
    {
      title: (
        <span style={{ fontWeight: "bold", color: "#1677ff" }}>Page Name</span>
      ),
      dataIndex: "moduleName",
      key: "moduleName",
    },
    // {
    //   title: (
    //     <span style={{ fontWeight: "bold", color: "#1677ff" }}>Category</span>
    //   ),
    //   dataIndex: "category",
    //   key: "category",
    // },
    {
      title: <span style={{ fontWeight: "bold", color: "#1677ff" }}>User</span>,
      dataIndex: "user",
      key: "user",
    },
    {
      title: (
        <span style={{ fontWeight: "bold", color: "#1677ff" }}>
          Created Date
        </span>
      ),
      dataIndex: "startTime",
      key: "startTime",
      render: (date) => <span> {dayjs(date).format("DD-MMM-YYYY")}</span>,
    },
    {
      title: <span style={{ fontWeight: "bold", color: "#1677ff" }}>Time</span>,
      dataIndex: "time",
      key: "time",
      render: (_, record) => (
        <span>{record?.startTime ? getTimeDiff(record) : "N/A"}</span>
      ),
    },
  ];

  return (
    <div
      className="MainContainer"
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        paddingRight: "12px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          marginTop: "23px",
        }}
      >
        {/* Summary Text */}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography.Title level={3}>Timelog Review</Typography.Title>
        </div>

        {/* Buttons  */}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Row gutter={16}>
            <Col sm={12} md={12}>
              <DatePicker placeholder="Select Date" style={{ width: "100%" }} />
            </Col>

            <Col sm={12} md={12}>
              <Form.Item name="TimeBtn">
                <Button type="primary">Add Manual</Button>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </div>

      {/* Table */}

      <StyledFormContainer>
        <Table
          columns={columns}
          dataSource={data || []}
          loading={isLoading}
          pagination={false}
          style={{ width: "100%" }}
        />
      </StyledFormContainer>
    </div>
  );
};

export default PatientTimeLogDetails;
