export const createDeviceInputs = [
  {
    formItemProps: { name: "deviceName", label: "Device Name" },
    inputProps: { type: "text", required: true }
  },
  {
    formItemProps: { name: "serialNumber", label: "Serial Number" },
    inputProps: { type: "text", min: 1 }
  }
];

export const deviceTypeOptions = [
  {
    value: 1,
    label: "Bluetooth"
  },
  {
    value: 2,
    label: "Cellular"
  },
  {
    value: 3,
    label: "Manual"
  }
];
