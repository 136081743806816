import Actions from "@/Pages/Patients/patientTable/actions";
import PatientInfo from "@/Pages/Patients/patientTable/columns/PatientInfo";
import { Badge } from "antd";

const columns = [
  {
    title: "Patient Name",
    dataIndex: "name",
    key: "name",
    width: 190,
    render: (_, record) => (
      <PatientInfo
        name={`${record.firstName} ${record.middleName} ${record.lastName}`}
        photoUrl={record.photoUrl}
        patientId={record.patientId}
      />
    ),
  },
  {
    title: "Alert",
    dataIndex: "alert",
    key: "alert",
  },
  {
    title: "Health Data",
    dataIndex: "health",
    key: "health",
    render: (text) => <a>{text}</a>,
  },
  {
    title: "Time Spent",
    dataIndex: "spent",
    key: "spent",
    render: (text) => (
      <span>
        <Badge status="success" text={text} />
      </span>
    ),
  },
  {
    title: "Active Days",
    dataIndex: "days",
    key: "days",
    render: (text) => (
      <span>
        <Badge status="error" text={text} />
      </span>
    ),
  },
  {
    title: "Last Readings",
    dataIndex: "last",
    key: "last",
    render: (text) => (
      <span>
        <Badge status="error" text={text} />
      </span>
    ),
  },
  {
    title: "Interactive Comma",
    dataIndex: "comma",
    key: "comma",
  },
  {
    title: "Billing Code",
    dataIndex: "billing",
    key: "billing",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    render: (_, record) => <Actions record={record} />,
  },
];

export default columns;
