import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Flex, Form, Radio, Typography, Button, Spin } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import { setIsModalOpen } from "@/store/features/ccm/ccmFormSlice";
import ErrorAlert from "@/components/ui/errorAlert";
import StepControls from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/StepControls";
import RiskClassificationModal from "@/components/forms/patientRiskClassification/riskClassificationModal";
import {
  StyledCheckBoxContainer,
  StyledMainContainer,
} from "@/components/forms/patientRiskClassification/styles";
import {
  useGetCCMAdditionalInfoQuery,
  useCreateCCMAdditionalNotesMutation,
  useUpdateCCMAdditionalNotesMutation,
} from "@/store/features/ccm/ccmApiSlice";

const options = [
  { label: "High Risk", value: 1 },
  { label: "Moderate Risk", value: 2 },
  { label: "Low Risk", value: 3 },
];

const RiskClassificationForm = ({ multiStep = false }) => {
  const [form] = Form.useForm();
  const { patientId } = useParams();
  const dispatch = useDispatch();
  const { currentStep } = useSelector((state) => state.ccmForm);
  const [isEditing, setIsEditing] = useState(false);
  const [isClassificationModalOpen, setIsClassificationModalOpen] =
    useState(false);

  const { data: riskClassification, isLoading: isRiskClassificationLoading } =
    useGetCCMAdditionalInfoQuery({
      patientId,
      pageNumber: 1,
      pageSize: 1,
      ccmAdditionalInfoTypeId: 2,
    });
  const [createRiskClassification, { isLoading: isCreating }] =
    useCreateCCMAdditionalNotesMutation();
  const [updateRiskClassification, { isLoading: isUpdating }] =
    useUpdateCCMAdditionalNotesMutation();

  useEffect(() => {
    if (riskClassification && riskClassification.length > 0) {
      form.setFieldsValue({
        riskClassificaitonTypeId:
          riskClassification[0].riskClassificaitonTypeId,
        isInitial: false,
      });
      setIsEditing(false);
    } else {
      setIsEditing(multiStep);
    }
  }, [riskClassification, form, multiStep]);

  const handleSubmit = async () => {
    const values = form.getFieldsValue(true);
    const payload = { ...values, ccmAdditionalInfoTypeId: 2, patientId };
    if (riskClassification && riskClassification.length > 0) {
      await updateRiskClassification({
        ...payload,
        ccmAdditionalInfoId: riskClassification[0].ccmAdditionalInfoId,
      });
    } else {
      await createRiskClassification({ ...payload, isInitial: true });
    }
    setIsEditing(false);
    if (multiStep) {
      dispatch(setIsModalOpen(false));
    }
  };

  const handleCancel = () => {
    if (riskClassification && riskClassification.length > 0) {
      form.setFieldsValue({
        riskClassificaitonTypeId:
          riskClassification[0].riskClassificaitonTypeId,
      });
    } else {
      form.resetFields();
    }
    setIsEditing(false);
  };

  if (multiStep && currentStep !== 11) return null;

  return (
    <Spin spinning={isRiskClassificationLoading}>
      <StyledMainContainer>
        <ErrorAlert />
        <Flex align="middle" gap="10px">
          <Typography.Title level={4}>
            What is the patient risk classification?
            <span
              onClick={() => setIsClassificationModalOpen(true)}
              style={{
                color: "#747474",
                cursor: "pointer",
                marginLeft: "10px",
              }}
            >
              <InfoCircleFilled style={{ fontSize: "18px" }} />
            </span>
          </Typography.Title>
        </Flex>
        <StyledCheckBoxContainer>
          <Form form={form} onFinish={handleSubmit}>
            <Form.Item name="riskClassificaitonTypeId">
              <Radio.Group
                options={options}
                disabled={multiStep ? false : !isEditing}
              />
            </Form.Item>
          </Form>
        </StyledCheckBoxContainer>

        <RiskClassificationModal
          visible={isClassificationModalOpen}
          onClose={() => setIsClassificationModalOpen(false)}
        />

        {multiStep ? (
          <StepControls
            isLoading={isCreating || isUpdating}
            onNextClick={form.submit}
          />
        ) : isEditing ? (
          <Flex justify="flex-end" gap={10}>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button
              type="primary"
              onClick={form.submit}
              loading={isCreating || isUpdating}
            >
              {riskClassification && riskClassification.length > 0
                ? "Update"
                : "Save"}
            </Button>
          </Flex>
        ) : (
          <Button onClick={() => setIsEditing(true)}>Edit</Button>
        )}
      </StyledMainContainer>
    </Spin>
  );
};

export default RiskClassificationForm;
