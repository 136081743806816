import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Checkbox, Flex, Form, Space, Typography, Button } from "antd";
import { setCurrentStep } from "@/store/features/ccm/ccmFormSlice";
import ErrorAlert from "@/components/ui/errorAlert";
import StepControls from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/StepControls";
import EmptyState from "@/components/ui/empty/Empty";
import {
  useGetCCMSymptomsQuery,
  useGetCCMSymptomMappingQuery,
  useCreateCCMSymptomMutation,
} from "@/store/features/ccm/ccmApiSlice";

const SymptomsForm = ({ multiStep = false }) => {
  const [form] = Form.useForm();
  const { patientId } = useParams();
  const dispatch = useDispatch();
  const { currentStep } = useSelector((state) => state.ccmForm);
  const [isEditing, setIsEditing] = useState(false);

  const {
    data: symptoms,
    isLoading: isSymptomsLoading,
    isError: isSymptomsError,
  } = useGetCCMSymptomsQuery();
  const { data: patientSymptoms, isLoading: isPatientSymptomsLoading } =
    useGetCCMSymptomMappingQuery({ patientId, pageNumber: 1, pageSize: 100 });
  const [createSymptom, { isLoading: isCreating }] =
    useCreateCCMSymptomMutation();

  useEffect(() => {
    if (patientSymptoms) {
      const selectedSymptoms = patientSymptoms.map(
        (symptom) => symptom.ccmSymptomId
      );
      form.setFieldsValue({ selectedSymptoms, isInitial: false });
      setIsEditing(false);
    } else {
      setIsEditing(multiStep);
    }
  }, [patientSymptoms, form, multiStep]);

  const handleFinish = async () => {
    const values = form.getFieldsValue(true);
    const payload = {
      ccmSymptomMappingList:
        values.selectedSymptoms?.map((ccmSymptomId) => ({ ccmSymptomId })) ||
        [],
      patientId,
      isInitial: !values?.isInitial ? false : true,
    };

    await createSymptom(payload);

    setIsEditing(false);
    if (multiStep) {
      dispatch(setCurrentStep(currentStep + 1));
    }
  };

  const handleCancel = () => {
    if (patientSymptoms) {
      const selectedSymptoms = patientSymptoms.map(
        (symptom) => symptom.ccmSymptomId
      );
      form.setFieldsValue({ selectedSymptoms });
    } else {
      form.resetFields();
    }
    setIsEditing(false);
  };

  if (multiStep && currentStep !== 3) return null;

  if (isSymptomsLoading || isPatientSymptomsLoading) {
    return <EmptyState />;
  }

  if (isSymptomsError) {
    return <EmptyState />;
  }

  return (
    <Form form={form} layout="vertical" onFinish={handleFinish}>
      <ErrorAlert />
      <Flex vertical gap={10}>
        <Typography.Title level={5} style={{ margin: "0px 15px" }}>
          What are some of the symptoms we will be able to reduce or prevent?
        </Typography.Title>

        <Form.Item name="selectedSymptoms">
          <Checkbox.Group disabled={multiStep ? false : !isEditing}>
            <Space direction="vertical">
              {symptoms?.map((option) => (
                <Checkbox key={option.ccmSymptomId} value={option.ccmSymptomId}>
                  <span style={{ color: "black", opacity: 1 }}>
                    {option.name}
                  </span>
                </Checkbox>
              ))}
            </Space>
          </Checkbox.Group>
        </Form.Item>
      </Flex>

      {multiStep ? (
        <StepControls isLoading={isCreating} onNextClick={form.submit} />
      ) : isEditing ? (
        <Flex justify="flex-end" gap={10}>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button type="primary" onClick={form.submit} loading={isCreating}>
            {patientSymptoms && patientSymptoms.length > 0 ? "Update" : "Save"}
          </Button>
        </Flex>
      ) : (
        <Button onClick={() => setIsEditing(true)}>Edit</Button>
      )}
    </Form>
  );
};

export default SymptomsForm;
