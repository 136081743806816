import EmptyState from "@/components/ui/empty/Empty";
import ErrorAlert from "@/components/ui/errorAlert";
import StepControls from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/StepControls";
import {
  useCreateCCMPreventiveCareMutation,
  useGetCCMPreventiveCareMeasureMappingQuery,
  useGetCCMPreventiveCareQuery,
} from "@/store/features/ccm/ccmApiSlice";
import { setCurrentStep } from "@/store/features/ccm/ccmFormSlice";
import { Button, Checkbox, Flex, Form, Space, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const PreventiveCareForm = ({ multiStep = false }) => {
  const [form] = Form.useForm();
  const { patientId } = useParams();
  const dispatch = useDispatch();
  const { currentStep } = useSelector((state) => state.ccmForm);
  const [isEditing, setIsEditing] = useState(false);

  const {
    data: preventiveCare,
    isLoading: isPreventiveCareLoading,
    isError: isPreventiveCareError,
  } = useGetCCMPreventiveCareQuery();
  const {
    data: patientPreventiveCare,
    isLoading: isPatientPreventiveCareLoading,
  } = useGetCCMPreventiveCareMeasureMappingQuery({
    patientId,
    pageNumber: 1,
    pageSize: 100,
  });
  const [createPreventiveCare, { isLoading: isCreating }] =
    useCreateCCMPreventiveCareMutation();

  useEffect(() => {
    if (patientPreventiveCare) {
      const selectedSymptoms = patientPreventiveCare.map(
        (item) => item.ccmPreventiveCareMeasureId
      );
      form.setFieldsValue({ selectedSymptoms, isInitial: false });
      setIsEditing(false);
    } else {
      setIsEditing(multiStep);
    }
  }, [patientPreventiveCare, form, multiStep]);

  const handleFinish = async () => {
    const values = form.getFieldsValue(true);
    const payload = {
      ccmPreventiveCareMeasureMapping:
        values.selectedSymptoms?.map((ccmPreventiveCareMeasureId) => ({
          ccmPreventiveCareMeasureId,
        })) || [],
      patientId,
      isInitial: !values?.isInitial ? false : true,
    };

    await createPreventiveCare(payload);

    setIsEditing(false);
    if (multiStep) {
      dispatch(setCurrentStep(currentStep + 1));
    }
  };

  const handleCancel = () => {
    if (patientPreventiveCare) {
      const selectedSymptoms = patientPreventiveCare.map(
        (item) => item.ccmPreventiveCareMeasureId
      );
      form.setFieldsValue({ selectedSymptoms });
    } else {
      form.resetFields();
    }
    setIsEditing(false);
  };

  if (multiStep && currentStep !== 7) return null;

  if (isPreventiveCareLoading || isPatientPreventiveCareLoading) {
    return <EmptyState />;
  }

  if (isPreventiveCareError) {
    return <EmptyState />;
  }

  return (
    <Spin spinning={isPreventiveCareLoading || isPatientPreventiveCareLoading}>
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <ErrorAlert />
        <Flex vertical gap={10}>
          <Typography.Title level={5} style={{ margin: "0px 15px" }}>
            What are some of the preventive care measures we will be able to
            implement or enhance?
          </Typography.Title>

          <Form.Item name="selectedSymptoms">
            <Checkbox.Group disabled={multiStep ? false : !isEditing}>
              <Space direction="vertical">
                {preventiveCare?.map((option) => (
                  <Checkbox
                    key={option.ccmPreventiveCareMeasureId}
                    value={option.ccmPreventiveCareMeasureId}
                  >
                    <span style={{ color: "black", opacity: 1 }}>
                      {option.name}
                    </span>
                  </Checkbox>
                ))}
              </Space>
            </Checkbox.Group>
          </Form.Item>
        </Flex>

        {multiStep ? (
          <StepControls isLoading={isCreating} onNextClick={form.submit} />
        ) : isEditing ? (
          <Flex justify="flex-end" gap={10}>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button type="primary" onClick={form.submit} loading={isCreating}>
              {patientPreventiveCare && patientPreventiveCare.length > 0
                ? "Update"
                : "Save"}
            </Button>
          </Flex>
        ) : (
          <Button onClick={() => setIsEditing(true)}>Edit</Button>
        )}
      </Form>
    </Spin>
  );
};

export default PreventiveCareForm;
