import { Button, Pagination, Typography, } from "antd";
import { StyledIconsDiv, StyledMainContainer } from "./Style";
import { PlusCircleOutlined, PlusOutlined } from "@ant-design/icons";

// External Conponents 
import AISearchComponent from "@/Pages/Dashboard/components/Searchbar";

const Header = () => {

    return (
        <StyledMainContainer>

            <Typography.Title level={3}>User</Typography.Title>



            <StyledIconsDiv>

                {/* pagination */}

                <Pagination
                    defaultCurrent={1}
                    total={50}
                    style={{
                        backgroundColor: '#fff',
                        padding: '4px',
                        borderRadius: '9px'
                    }}
                />


                {/* Search Bar Component */}

                <AISearchComponent />


                {/* Plus Button */}

                <Button
                    type="primary"
                    shape="circle"
                    icon={<PlusOutlined
                        style={{ fontSize: '20px', fontWeight: 'bolder' }}
                    />}
                />

            </StyledIconsDiv>

        </StyledMainContainer>
    )
}

export default Header;




const monitoringOptions = [
    {
        label: 'RPM',
        value: 'rpm',
    },
    {
        label: 'CCM',
        value: 'ccm',
    },
    {
        label: 'PCM',
        value: 'pcm',
    },
    {
        label: 'RTM',
        value: 'rtm',
    },
]


