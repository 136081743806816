import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Checkbox, Flex, Form, Space, Typography, Button, Spin } from "antd";
import { setCurrentStep } from "@/store/features/ccm/ccmFormSlice";
import ErrorAlert from "@/components/ui/errorAlert";
import StepControls from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/StepControls";
import EmptyState from "@/components/ui/empty/Empty";
import {
  useGetCCMBarriersQuery,
  useGetCCMBarrierMappingQuery,
  useCreateCCMBarrierMutation,
} from "@/store/features/ccm/ccmApiSlice";

const BarriersForm = ({ multiStep = false }) => {
  const [form] = Form.useForm();
  const { patientId } = useParams();
  const dispatch = useDispatch();
  const { currentStep } = useSelector((state) => state.ccmForm);
  const [isEditing, setIsEditing] = useState(false);

  const { data: barriers, isLoading: isBarriersLoading } =
    useGetCCMBarriersQuery();
  const { data: patientBarriers, isLoading: isPatientBarriersLoading } =
    useGetCCMBarrierMappingQuery({ patientId, pageNumber: 1, pageSize: 100 });
  const [createBarriers, { isLoading: isCreating }] =
    useCreateCCMBarrierMutation();

  useEffect(() => {
    if (patientBarriers && patientBarriers.length > 0) {
      const selectedBarriers = patientBarriers.map(
        (barrier) => barrier.ccmBarrierId
      );
      form.setFieldsValue({
        selectedGoals: selectedBarriers,
        barriersToCare: selectedBarriers.length === 0,
        isInitial: false,
      });
      setIsEditing(false);
    } else {
      setIsEditing(multiStep);
    }
  }, [patientBarriers, form, multiStep]);

  const handleFinish = async (values) => {
    const payload = {
      createCCMBarrierMappingDto:
        values.selectedGoals?.map((ccmBarrierId) => ({ ccmBarrierId })) || [],
      patientId,
      isInitial: !values?.isInitial ? false : true,
    };

    await createBarriers(payload);

    setIsEditing(false);
    if (multiStep) {
      dispatch(setCurrentStep(currentStep + 1));
    }
  };

  const handleCancel = () => {
    if (patientBarriers && patientBarriers.length > 0) {
      const selectedBarriers = patientBarriers.map(
        (barrier) => barrier.ccmBarrierId
      );
      form.setFieldsValue({
        selectedGoals: selectedBarriers,
        barriersToCare: selectedBarriers.length === 0,
      });
    } else {
      form.resetFields();
    }
    setIsEditing(false);
  };

  if (multiStep && currentStep !== 2) return null;

  if (isBarriersLoading || isPatientBarriersLoading) {
    return <EmptyState />;
  }

  return (
    <Spin spinning={isBarriersLoading || isPatientBarriersLoading}>
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <ErrorAlert />
        <Flex vertical gap={10}>
          <Typography.Title level={5} style={{ margin: "0px 15px" }}>
            What are some things that might make it hard for the patient to
            reach his / her goals?
          </Typography.Title>

          <Form.Item name="barriersToCare" gap="middle">
            <Flex>
              <Typography.Title level={5} style={{ margin: "0px 15px" }}>
                Barriers to Care
              </Typography.Title>
              <Checkbox disabled={multiStep ? false : !isEditing}>
                <span style={{ color: "black", opacity: 1 }}>None</span>
              </Checkbox>
            </Flex>
          </Form.Item>

          <Form.Item name="selectedGoals">
            <Checkbox.Group disabled={multiStep ? false : !isEditing}>
              <Flex gap="large">
                {[1, 2, 3].map((groupId) => (
                  <Space key={groupId} direction="vertical">
                    <Typography.Title level={5} style={{ margin: "0px 15px" }}>
                      {groupId === 1
                        ? "Social"
                        : groupId === 2
                          ? "Financial"
                          : "Assistive Devices"}
                    </Typography.Title>
                    {barriers
                      ?.filter(
                        (barrier) => barrier.barrierGroupTypeId === groupId
                      )
                      .map((option) => (
                        <Checkbox
                          key={option.ccmBarrierId}
                          value={option.ccmBarrierId}
                        >
                          <span style={{ color: "black", opacity: 1 }}>
                            {option.name}
                          </span>
                        </Checkbox>
                      ))}
                  </Space>
                ))}
              </Flex>
            </Checkbox.Group>
          </Form.Item>
        </Flex>

        {multiStep ? null : isEditing ? (
          <Flex justify="flex-end" gap={10}>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button type="primary" htmlType="submit" loading={isCreating}>
              {patientBarriers && patientBarriers.length > 0
                ? "Update"
                : "Save"}
            </Button>
          </Flex>
        ) : (
          <Button onClick={() => setIsEditing(true)}>Edit</Button>
        )}

        {multiStep && (
          <StepControls isLoading={isCreating} onNextClick={form.submit} />
        )}
      </Form>
    </Spin>
  );
};

export default BarriersForm;
