import { apiSlice } from "@/store/features/api/apiSlice";
import { PATIENT_NOTE_URL } from "@/constants/apiDefinition";

export const patientNotesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPatientNotes: builder.query({
      query: ({ patientId, pageNumber, pageSize }) => ({
        url: PATIENT_NOTE_URL,
        params: { PageNumber: pageNumber, PageSize: pageSize, patientId },
      }),
      transformResponse: (response, meta) => {
        const paginationHeader = meta.response.headers.get("Pagination");
        return {
          data: response,
          pagination: paginationHeader ? JSON.parse(paginationHeader) : null,
        };
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: "PatientNote", id })),
              { type: "PatientNote", id: "LIST" },
            ]
          : [{ type: "PatientNote", id: "LIST" }],
    }),
    addPatientNote: builder.mutation({
      query: (body) => ({
        url: PATIENT_NOTE_URL,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "PatientNote", id: "LIST" }],
    }),
    updatePatientNote: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `${PATIENT_NOTE_URL}/${id}`,
        method: "PUT",
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "PatientNote", id }],
    }),
    deletePatientNote: builder.mutation({
      query: (id) => ({
        url: `${PATIENT_NOTE_URL}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [{ type: "PatientNote", id }],
    }),
  }),
});

export const {
  useGetPatientNotesQuery,
  useAddPatientNoteMutation,
  useUpdatePatientNoteMutation,
  useDeletePatientNoteMutation,
} = patientNotesApiSlice;
