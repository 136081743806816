import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  token: localStorage.getItem("token") || null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      console.log("Payload", action.payload);
      const { token } = action.payload;
      state.isAuthenticated = !!token;
      state.token = token;
      state = { ...state, ...action.payload };
      // Persist token to localStorage
      if (token) {
        localStorage.setItem("token", token);
      } else {
        localStorage.removeItem("token");
      }
    },
    clearAuth: (state) => {
      state.isAuthenticated = false;
      state.token = null;
      state.roles = null;
      state.permissions = null;
      state.user = null;
      localStorage.removeItem("token");
    },
    updateUser: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },
  },
});

export const { setAuth, clearAuth, updateUser } = authSlice.actions;
export default authSlice.reducer;
