import { useState } from 'react'
import { StyledMainContainer, StyledCheckBoxContainer } from './Style'
import { Typography, Select, Input, Form, Checkbox } from 'antd'
const { Option } = Select
const { TextArea } = Input
const { Text } = Typography
const onChange = (checkedValues) => {
  console.log('checked = ', checkedValues)
}

const options = [
  {
    label: 'Not Applicable',
    value: 'notApplicable',
  },
  {
    label: 'Maintained Ideal Height and Weight ratio',
    value: 'ideal',
  },
  {
    label: 'Be alchol free',
    value: 'alchol',
  },
  {
    label: 'Complete therapy/detox',
    value: 'complete',
  },
  {
    label: 'Consistently attend support group(AA) sessions',
    value: 'support',
  },
  {
    label: 'Reduce anxiety and depression',
    value: 'anxiety',
  },
  {
    label: 'Other(s)',
    value: 'others',
  },
]

export default function FormContent() {
  return (
    <StyledMainContainer>
      <h3>
        1 OF 1 <span style={{ color: 'gray' }}>IN THE GOALS SECTION</span>
      </h3>
      <h1>What are the patient's goal for diseas management?</h1>

      <StyledCheckBoxContainer>
        <Checkbox.Group
          options={options}
          onChange={onChange}
          style={{ display: 'flex', flexDirection: 'column', gap: '13px' }} // Ensure vertical alignment
        />
      </StyledCheckBoxContainer>

      <DescriptionInput placeholder={'Add Notes'} />
    </StyledMainContainer>
  )
}

// Description Fuction.....//

export const DescriptionInput = ({ placeholder }) => {
  const [description, setDescription] = useState('')
  const maxChars = 1300

  const handleChange = (e) => {
    const inputText = e.target.value
    const alphabetCount = inputText.replace(/[^a-zA-Z]/g, '').length

    if (alphabetCount <= maxChars) {
      setDescription(inputText)
    } else {
      // Truncate the input to the maximum allowed alphabet count
      const truncatedText = inputText.split('').reduce(
        (acc, char) => {
          if (acc.alphabetCount < maxChars && /[a-zA-Z]/.test(char)) {
            acc.text += char
            acc.alphabetCount++
          } else if (!/[a-zA-Z]/.test(char)) {
            acc.text += char
          }
          return acc
        },
        { text: '', alphabetCount: 0 }
      ).text

      setDescription(truncatedText)
    }
  }

  const alphabetCount = description.replace(/[^a-zA-Z]/g, '').length
  const isNearLimit = alphabetCount >= maxChars * 0.9
  const isAtLimit = alphabetCount === maxChars

  return (
    <div>
      <TextArea
        value={description}
        onChange={handleChange}
        rows={4}
        placeholder={placeholder}
        style={{ width: '400px', marginTop: '10px' }}
      />
      <Text
        style={{
          display: 'block',
          textAlign: 'right',
          marginTop: '8px',
          color: isAtLimit ? 'red' : isNearLimit ? 'orange' : 'inherit',
          width: '400px',
        }}
      >
        {alphabetCount} / {maxChars} alphabets
      </Text>
    </div>
  )
}
