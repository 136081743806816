import { Tag } from 'antd'

export default function PriorityTag({ priority }) {
  const priorityTextMap = {
    high: {
      color: '#EF7157',
      text: 'High Priority',
      bgColor: '#F6EFED',
    },
    moderate: {
      color: '#FF9A24',
      text: 'Moderate',
      bgColor: '#FFF7EB',
    },
    low: {
      color: '#63A3B0',
      text: 'Low',
      bgColor: '#ECF9FC',
    },
  }

  return (
    <Tag
      bordered={false}
      style={{
        backgroundColor: priorityTextMap?.[priority]?.bgColor,
        color: priorityTextMap?.[priority]?.color,
        padding: '8px 16px',
        borderRadius: '30px',
        fontSize: '14px',
        fontWeight: 600,
      }}
    >
      {priorityTextMap[priority]?.text}
    </Tag>
  )
}
