/**
 * External dependencies
 */
import { Menu } from "antd";
/**
 * Internal dependencies
 */
import {
  DashboardIcon,
  DeviceIcon,
  HelpIcon,
  MessageIcon,
  PatientIcon,
  ReportIcon
} from "../../../assets/icons";
import {
  BottomSection,
  ContentLayout,
  CustomMenuItem,
  Logo,
  MenuWrapper,
  StyledSider
} from "./style";

import { useLocation, useNavigate } from "react-router-dom";
import { DesktopOutlined, ShoppingCartOutlined } from "@ant-design/icons";

/**
 * Sidebar component for the application.
 * This component creates a collapsible sidebar using Ant Design's Layout.Sider
 * and custom styled components.
 *
 * @param {Object} props - The component props
 * @param {boolean} props.collapsed - Whether the sidebar is collapsed
 * @param {function} props.setCollapsed - Function to update the collapsed state
 * @returns {React.Component} A sidebar component
 */
const Sidebar = ({ collapsed, setCollapsed }) => {
  /**
   * Configuration for the main menu items.
   * Each item has a key, icon, and label.
   * @type {Array<{key: string, icon: React.Element, label: string}>}
   */
  const activeMenu = useLocation().pathname;
  const navigate = useNavigate();

  const mainMenuItems = [
    {
      key: "/dashboard",
      icon: <DashboardIcon />,
      label: "Dashboard"
    },
    {
      key: "/devices",
      icon: <DeviceIcon />,
      label: "Devices",
      children: [
        {
          key: "device-list",
          label: "All Devices",
          icon: <DesktopOutlined />
        },
        {
          key: "device-order-list",
          label: "Device Orders",
          icon: <ShoppingCartOutlined />
        }
      ]
    },
    {
      key: "/messages",
      icon: <MessageIcon />,
      label: "Messages"
    },
    {
      key: "/patients",
      icon: <PatientIcon />,
      label: "Patients"
    },
    {
      key: "/reports",
      icon: <ReportIcon />,
      label: "Reports"
    }
  ];

  return (
    <StyledSider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => {
        setCollapsed(value);
      }}
    >
      <ContentLayout>
        {/* Logo component that adjusts based on sidebar collapse state */}
        <Logo collapsed={collapsed} />

        <MenuWrapper>
          {/* Main menu using Ant Design's Menu component */}
          <Menu
            onClick={(item) => {
              navigate(item.key);
            }}
            theme="dark"
            defaultSelectedKeys={[activeMenu || "/dashboard"]}
            mode="inline"
            items={mainMenuItems}
          />
        </MenuWrapper>

        <BottomSection>
          {/* Custom menu item for Help, placed at the bottom of the sidebar */}
          <CustomMenuItem
            icon={<HelpIcon />}
            label="Help"
            collapsed={collapsed}
          />
        </BottomSection>
      </ContentLayout>
    </StyledSider>
  );
};

export default Sidebar;
