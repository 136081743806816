import { RouterProvider } from 'react-router-dom'
import './App.css'
import appRouter from './routes/router'
import NotificationSnackbar from './components/ui/notification/NotificationSnackbar'

function App() {
  return (
    <>
      <RouterProvider router={appRouter} />
      <NotificationSnackbar />
    </>
  )
}

export default App
