import { ForwardOutlined, PhoneOutlined } from "@ant-design/icons";
import { Col, Form, Input, Row, Select } from "antd";
import { StyledContainer, StyledParagraph, StyledTitle } from "./Style";
const PhoneNumber = () => {
  return (
    <StyledContainer>
      <StyledTitle level={4}>Phone Number Selection</StyledTitle>
      <StyledParagraph>
        Please select the phone number to be visible during SMS and Audio Call
        to patient
      </StyledParagraph>
      <Form layout="vertical">
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              name="outgoingNumber"
              label="Select Outgoing Number"
              rules={[{ required: true, message: "Number is Required!" }]}
            >
              <Select
                placeholder="Select Out Going Number"
                options={[
                  { label: "+1293482984", value: "+1293482984" },
                  { label: "+9104821830", value: "+9104821830" },
                  { label: "+859384932", value: "+859384932" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              name="phone"
              label="Phone Number"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input
                prefix={<PhoneOutlined rotate={90} />}
                placeholder="Phone"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              name="forwardNumber"
              label="Call Forwarding Number"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input prefix={<ForwardOutlined />} placeholder="Mobile Number" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </StyledContainer>
  );
};

export default PhoneNumber;
