import EmptyState from "@/components/ui/empty/Empty";
import useGetAllPatients from "@/hooks/dashboard/useGetAllPatients";
import { Col, Flex, Row, Typography } from "antd";
import { useState } from "react";
import MonitoringSelector from "../Dashboard/components/MonitoringSelector";
import AISearchComponent from "../Dashboard/components/Searchbar";
import FilterForm from "../Dashboard/filter";
import PatientCard from "./components/PatientCard";

const CcmDashboard = () => {
  const { isLoading, patientList } = useGetAllPatients();
  const [showFilters, setShowFilters] = useState(false);
  return (
    <Flex vertical style={{ minHeight: "100vh" }}>
      <Row
        justify="space-between"
        align="middle"
        style={{ margin: "20px 0px" }}
      >
        <Col xs={24} md={8}>
          <Typography.Title level={3}>Patient List</Typography.Title>
        </Col>
        <Col xs={24} md={8}>
          <AISearchComponent onFilter={setShowFilters} />
        </Col>
        <Col xs={24} md={8}>
          <Flex justify="flex-end">
            <MonitoringSelector options={monitoringOptions} value={"rpm"} />
          </Flex>
        </Col>
      </Row>
      <div style={{ overflow: "auto" }}>
        {patientList?.length ? (
          patientList?.map((patient) => (
            <PatientCard
              key={patient.id}
              patient={patient}
              loading={isLoading}
            />
          ))
        ) : (
          <EmptyState />
        )}
      </div>
      <FilterForm open={showFilters} setOpen={setShowFilters} />
    </Flex>
  );
};

export default CcmDashboard;

const monitoringOptions = [
  {
    label: "RPM",
    value: "rpm",
  },
  {
    label: "CCM",
    value: "ccm",
  },
  {
    label: "PCM",
    value: "pcm",
  },
  {
    label: "RTM",
    value: "rtm",
  },
];
