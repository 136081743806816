import { Form, Input, Select } from 'antd'
import styled from 'styled-components'
import AlertRangePicker from '../alertRangePicker'
import { SliderLabel } from '../alertRangePicker/styles'
const { TextArea } = Input

const Container = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
`

const NotesContainer = styled.div`
  margin-bottom: 20px;
`

const StyledTextArea = styled(TextArea)`
  width: 100%;
  min-height: 100px;
`

const SetDefaultButton = styled.button`
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #f0f0f0;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
`

const FrequencyContainer = styled.div`
  margin-top: 20px;
`

const BloodPressureComponent = () => {
  const [form] = Form.useForm()

  return (
    <Container>
      <AlertRangePicker
        label={'SYSTOLIC'}
        name="systolic"
        min={30}
        max={260}
        rules={[
          {
            required: true,
            message: 'Please select systolic blood pressure range!',
          },
        ]}
      />
      <AlertRangePicker
        label={'DIASTOLIC'}
        name="diastolic"
        min={30}
        max={260}
        rules={[
          {
            required: true,
            message: 'Please select diastolic blood pressure range!',
          },
        ]}
      />
      <NotesContainer>
        <SliderLabel>Intervention Notes</SliderLabel>
        <StyledTextArea placeholder="Intervention Notes" />
        <SetDefaultButton>Set Default</SetDefaultButton>
      </NotesContainer>

      <FrequencyContainer>
        <SliderLabel>Frequency</SliderLabel>
        <Select style={{ width: 120 }} placeholder="Select">
          <Select.Option value="daily">Daily</Select.Option>
          <Select.Option value="weekly">Weekly</Select.Option>
          <Select.Option value="monthly">Monthly</Select.Option>
        </Select>
      </FrequencyContainer>
    </Container>
  )
}

export default BloodPressureComponent
