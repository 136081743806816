import { useState } from 'react'
import { StyledMainContainer, StyledCheckBoxContainer } from './Style'
import { Typography, Select, Input, Form, Checkbox } from 'antd'
const { Option } = Select
const { TextArea } = Input
const { Text } = Typography
const onChange = (checkedValues) => {
  console.log('checked = ', checkedValues)
}

// Socials Options

const socials = [
  {
    label: 'Blind',
    value: 'blind',
  },
  {
    label: 'Deaf',
    value: 'deaf',
  },
  {
    label: 'Hearing',
    value: 'hearing',
  },
  {
    label: 'Language',
    value: 'language',
  },
  {
    label: 'Literacy',
    value: 'literacy',
  },
  {
    label: 'Social isolation',
    value: 'social',
  },
  {
    label: 'vision',
    value: 'vision',
  },
]

//Financial Options

const financial = [
  {
    label: 'Co-pays',
    value: 'coPays',
  },
  {
    label: 'Medications',
    value: 'medication',
  },
  {
    label: 'Neurtritions',
    value: 'neutral',
  },
  {
    label: 'Transportaion',
    value: 'transport',
  },
  {
    label: 'Uninsured',
    value: 'unInsured',
  },
]

// Assistive Device Options

const assistiveDevices = [
  {
    label: 'Crane',
    value: 'crane',
  },
  {
    label: 'Crutches',
    value: 'crutches',
  },
  {
    label: 'Hearing Aid',
    value: 'hearing',
  },
  {
    label: 'Prosthetic limb',
    value: 'prosthetic',
  },
  {
    label: 'Spine Brace ',
    value: 'spine',
  },
  {
    label: 'Walker',
    value: 'walker',
  },
  {
    label: 'Wheel Chair',
    value: 'wheel',
  },
]

export default function FormContent() {
  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`)
  }
  return (
    <StyledMainContainer>
      <h3>
        1 OF 1 <span style={{ color: 'gray' }}>IN THE BARRIERS SECTION</span>
      </h3>
      <h1>
        What are somethings that might make it hard for patient to reach his/her
        goals?
      </h1>
      <h3 style={{ marginTop: '15px' }}>
        Barriers to Care{' '}
        <Checkbox style={{ marginLeft: '3px' }} onChange={onChange}>
          None
        </Checkbox>
      </h3>

      <StyledCheckBoxContainer>
        {/* Socials CheckBox */}
        <Form.Item
          label={
            <span style={{ fontSize: '21px', fontWeight: 'bold' }}>
              Socials
            </span>
          }
          labelCol={{ span: 24 }}
        >
          <Checkbox.Group
            options={socials}
            onChange={onChange}
            style={{ display: 'flex', flexDirection: 'column', gap: '13px' }}
          />
        </Form.Item>

        <Form.Item
          label={
            <span style={{ fontSize: '21px', fontWeight: 'bold' }}>
              Financial
            </span>
          }
          labelCol={{ span: 24 }}
        >
          {/* Financial CheckBox */}
          <Checkbox.Group
            options={assistiveDevices}
            onChange={onChange}
            style={{ display: 'flex', flexDirection: 'column', gap: '13px' }}
          />
        </Form.Item>

        <Form.Item
          label={
            <span style={{ fontSize: '21px', fontWeight: 'bold' }}>
              Assistive Device
            </span>
          }
          labelCol={{ span: 24 }}
        >
          {/* Assistive Device CheckBox */}
          <Checkbox.Group
            options={financial}
            onChange={onChange}
            style={{ display: 'flex', flexDirection: 'column', gap: '13px' }}
          />
        </Form.Item>
      </StyledCheckBoxContainer>

      <DescriptionInput placeholder={'Add Notes'} />
    </StyledMainContainer>
  )
}

// Description Fuction.....//

export const DescriptionInput = ({ placeholder }) => {
  const [description, setDescription] = useState('')
  const maxChars = 1300

  const handleChange = (e) => {
    const inputText = e.target.value
    const alphabetCount = inputText.replace(/[^a-zA-Z]/g, '').length

    if (alphabetCount <= maxChars) {
      setDescription(inputText)
    } else {
      // Truncate the input to the maximum allowed alphabet count
      const truncatedText = inputText.split('').reduce(
        (acc, char) => {
          if (acc.alphabetCount < maxChars && /[a-zA-Z]/.test(char)) {
            acc.text += char
            acc.alphabetCount++
          } else if (!/[a-zA-Z]/.test(char)) {
            acc.text += char
          }
          return acc
        },
        { text: '', alphabetCount: 0 }
      ).text

      setDescription(truncatedText)
    }
  }

  const alphabetCount = description.replace(/[^a-zA-Z]/g, '').length
  const isNearLimit = alphabetCount >= maxChars * 0.9
  const isAtLimit = alphabetCount === maxChars

  return (
    <div>
      <TextArea
        value={description}
        onChange={handleChange}
        rows={4}
        placeholder={placeholder}
        style={{ width: '400px', marginTop: '10px' }}
      />
      <Text
        style={{
          display: 'block',
          textAlign: 'right',
          marginTop: '8px',
          color: isAtLimit ? 'red' : isNearLimit ? 'orange' : 'inherit',
          width: '400px',
        }}
      >
        {alphabetCount} / {maxChars} alphabets
      </Text>
    </div>
  )
}
