import { Button, Divider, Typography } from 'antd'
import {
  StyledDivContainer,
  StyledFooterContainer,
  StyledMainContainer,
} from './Style'
import Sidebar from './Components/Sidebar'
import FormContent from './Components/FormContent'

export default function Risk() {
  return (
    <StyledMainContainer>
      <Typography.Title level={3}>Chronic Care Management</Typography.Title>
      <StyledDivContainer>
        <Sidebar />
        <FormContent />
      </StyledDivContainer>
      <Divider />

      <StyledFooterContainer>
        <Button
          style={{ padding: '16px', borderRadius: '7px', marginRight: '7px' }}
        >
          Back
        </Button>
        <Button type="primary">Submit</Button>
      </StyledFooterContainer>
    </StyledMainContainer>
  )
}
