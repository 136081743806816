import { Button, Pagination } from "antd";
import { StyledIconsDiv, Container } from "./style";
import MonitoringSelector from "@/Pages/Dashboard/components/MonitoringSelector";
import AISearchComponent from "@/Pages/Dashboard/components/Searchbar";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <MonitoringSelector options={monitoringOptions} />

      <StyledIconsDiv>
        {/* Search Bar Component */}

        <AISearchComponent />

        {/* Plus Button */}

        <Button
          type="primary"
          shape="circle"
          icon={
            <PlusOutlined style={{ fontSize: "20px", fontWeight: "bolder" }} />
          }
          onClick={() => navigate("/patients/add")}
        />
      </StyledIconsDiv>
    </Container>
  );
};

export default Header;

const monitoringOptions = [
  {
    label: "RPM",
    value: "rpm",
  },
  {
    label: "CCM",
    value: "ccm",
  },
  {
    label: "PCM",
    value: "pcm",
  },
  {
    label: "RTM",
    value: "rtm",
  },
];
