import { Typography } from "antd";
import LocationManagement from "./Component/LocationManagement";
import PhoneNumber from "./Component/PhoneNumber";
import SmsReminder from "./Component/SmsReminder";
import { StyledDivContainer, StyledMainContainer } from "./Style";

export default function GlobalSetting() {
  return (
    <StyledMainContainer>
      <Typography.Title level={2}>
        Global Settings(Practice Interface ID-12484)
      </Typography.Title>
      <StyledDivContainer>
        {/* Phone Number */}

        <PhoneNumber />

        {/* Sms Reminder */}
        <SmsReminder />

        {/* Location Management */}

        <LocationManagement />
      </StyledDivContainer>
    </StyledMainContainer>
  );
}
