import Header from "./header";
import PatientsTable from "./patientTable";
import { Container } from "./styles";
const Patients = () => {
  return (
    <Container>
      <Header />
      <PatientsTable />
    </Container>
  );
};

export default Patients;
