import { Table, Button } from "antd";
import { useGetPatientNotesQuery } from "@/store/features/patientNotes/patientNoteApiSlice";
import { useState } from "react";
import dayjs from "dayjs";
const NotesTable = ({ patientId }) => {
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  });
  const { data, isLoading } = useGetPatientNotesQuery({
    ...pagination,
    patientId,
  });
  const { data: notes, pagination: paginationData } = data || {};
  const columns = [
    {
      title: "Date/Time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value) => dayjs(value)?.format("DD-MMM-YYYY HH:mm A"),
    },
    { title: "Notes", dataIndex: "notes", key: "notes" },
    { title: "Activity For", dataIndex: "activityFor", key: "activityFor" },
    { title: "Created By", dataIndex: "createdBy", key: "createdBy" },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button type="link" onClick={() => handleEditNote(record)}>
          Edit
        </Button>
      ),
    },
  ];

  const handleEditNote = (record) => {
    // Implement edit functionality
    console.log("Editing note:", record);
  };

  return (
    <Table
      columns={columns}
      dataSource={notes}
      rowKey={(record) => record.id}
      loading={isLoading}
      pagination={{
        pageSize: pagination.pageSize,
        current: pagination.pageNumber,
        total: paginationData?.totalItems || 0,
        onChange: (page) =>
          setPagination((prev) => ({
            ...prev,
            pageNumber: page,
          })),
      }}
    />
  );
};

export default NotesTable;
