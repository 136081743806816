import {
  PATIENT_DETAILS_ASSESSMENT_PATH,
  PATIENT_DETAILS_ATTACHMENTS_PATH,
  PATIENT_DETAILS_BLOOD_PRESSURE_PATH,
  PATIENT_DETAILS_CARE_PLAN_PATH,
  PATIENT_DETAILS_DEVICES_PATH,
  PATIENT_DETAILS_GLUCOSE_PATH,
  PATIENT_DETAILS_PATH,
  PATIENT_DETAILS_PULSE_PATH,
  PATIENT_DETAILS_SPO2_PATH,
  PATIENT_DETAILS_TEMPERATURE_PATH,
  PATIENT_DETAILS_TIMELOG_PATH,
  PATIENT_DETAILS_WEIGHT_PATH,
} from "@/routes/routeDefinitions";
import { Button, Flex } from "antd";
import { Link, useLocation } from "react-router-dom";
import PatientInfo from "./PatientInfo";
import { StyledFormContainer } from "./Style";

const navigationItems = [
  { path: PATIENT_DETAILS_PATH, label: "Patient Details" },
  { path: PATIENT_DETAILS_CARE_PLAN_PATH, label: "Care Plan" },
  { path: PATIENT_DETAILS_BLOOD_PRESSURE_PATH, label: "Blood Pressure" },
  { path: PATIENT_DETAILS_PULSE_PATH, label: "Pulse" },
  { path: PATIENT_DETAILS_WEIGHT_PATH, label: "Weight" },
  { path: PATIENT_DETAILS_SPO2_PATH, label: "SpO2" },
  { path: PATIENT_DETAILS_GLUCOSE_PATH, label: "Glucose" },
  { path: PATIENT_DETAILS_TEMPERATURE_PATH, label: "Temperature" },
  { path: PATIENT_DETAILS_DEVICES_PATH, label: "Devices" },
  { path: PATIENT_DETAILS_TIMELOG_PATH, label: "TimeLog" },
];

export default function Header() {
  const { pathname } = useLocation();

  const activePath = pathname.split("/").at(-1);

  return (
    <>
      <StyledFormContainer>
        <PatientInfo />
        <div className="lower">
          <Flex gap="small" wrap>
            {navigationItems.map(({ path, label }) => (
              <Link key={path} to={path}>
                <Button type={activePath === path ? "primary" : "default"}>
                  {label}
                </Button>
              </Link>
            ))}
          </Flex>
        </div>
      </StyledFormContainer>
    </>
  );
}
