import ChatIcon from "@/assets/icons/ChatIcon";
import { Button, Tooltip } from "antd";

const Messages = () => {
  return (
    <Tooltip title="Messages">
      <Button icon={<ChatIcon />} />
    </Tooltip>
  );
};

export default Messages;
