import { FileDoneOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
const Tasks = () => {
  return (
    <Tooltip title="Tasks">
      <Button icon={<FileDoneOutlined size={"large"} />} />
    </Tooltip>
  );
};

export default Tasks;
