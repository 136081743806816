import React from 'react'
import { Popover, Steps } from 'antd'
import { StyledMainContainer } from './Style'
const customDot = (dot, { status, index }) => (
  <Popover
    content={
      <span>
        step {index} status: {status}
      </span>
    }
  >
    {dot}
  </Popover>
)
const description = 'You can hover on the dot.'
export default function Sidebar() {
  return (
    // {Steps in progress} //
    <StyledMainContainer>
      <Steps
        direction="vertical"
        size="small"
        current={1}
        items={[
          {
            title: 'Patient History',
          },
          {
            title: 'Goals',
          },
          {
            title: 'Barriers',
          },
          {
            title: 'Symptoms',
          },
          {
            title: 'Allergies',
          },
          {
            title: 'Medications',
          },
          {
            title: 'Monitoring',
          },
          {
            title: 'Preventive Care',
          },
          {
            title: 'Psychosocial and Neuropsychological Testing',
          },
          {
            title: 'Self Management',
          },
          {
            title: 'Additional Notes',
          },
          {
            title: 'Patient Risk Classification',
          },
        ]}
      />
    </StyledMainContainer>
  )
}
