import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Flex, Form, Radio, Space, Typography, Button, Spin } from "antd";
import { setCurrentStep } from "@/store/features/ccm/ccmFormSlice";
import ErrorAlert from "@/components/ui/errorAlert";
import StepControls from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/StepControls";
import {
  useGetCCMAdditionalInfoQuery,
  useCreateCCMAdditionalNotesMutation,
  useUpdateCCMAdditionalNotesMutation,
} from "@/store/features/ccm/ccmApiSlice";

const AllergiesForm = ({ multiStep = false }) => {
  const [form] = Form.useForm();
  const { patientId } = useParams();
  const dispatch = useDispatch();
  const { currentStep } = useSelector((state) => state.ccmForm);
  const [isEditing, setIsEditing] = useState(false);

  const { data: allergiesInfo, isLoading: isAllergiesLoading } =
    useGetCCMAdditionalInfoQuery({
      patientId,
      pageNumber: 1,
      pageSize: 1,
      ccmAdditionalInfoTypeId: 1,
    });
  const [createAllergiesInfo, { isLoading: isCreating }] =
    useCreateCCMAdditionalNotesMutation();
  const [updateAllergiesInfo, { isLoading: isUpdating }] =
    useUpdateCCMAdditionalNotesMutation();

  useEffect(() => {
    if (allergiesInfo && allergiesInfo.length > 0) {
      form.setFieldsValue({
        hasAllergies: allergiesInfo[0].hasAllergies,
        isInitial: false,
      });
      setIsEditing(false);
    } else {
      setIsEditing(multiStep);
    }
  }, [allergiesInfo, form, multiStep]);

  const handleFinish = async (values) => {
    const payload = {
      ...values,
      ccmAdditionalInfoTypeId: 1,
      patientId,
    };
    if (allergiesInfo && allergiesInfo.length > 0) {
      await updateAllergiesInfo({
        ...payload,
        ccmAdditionalInfoId: allergiesInfo[0].ccmAdditionalInfoId,
        isInitial: false,
      });
    } else {
      await createAllergiesInfo({ ...payload, isInitial: true });
    }
    setIsEditing(false);
    if (multiStep) {
      dispatch(setCurrentStep(currentStep + 1));
    }
  };

  const handleCancel = () => {
    if (allergiesInfo && allergiesInfo.length > 0) {
      form.setFieldsValue({ hasAllergies: allergiesInfo[0].hasAllergies });
    } else {
      form.resetFields();
    }
    setIsEditing(false);
  };

  if (multiStep && currentStep !== 4) return null;

  return (
    <Form form={form} layout="vertical" onFinish={handleFinish}>
      <ErrorAlert />
      <Flex vertical gap={10}>
        <Typography.Title level={5} style={{ margin: "0px 15px" }}>
          Do you have allergies?
        </Typography.Title>

        <Spin spinning={isAllergiesLoading}>
          <Form.Item name="hasAllergies">
            <Radio.Group disabled={multiStep ? false : !isEditing}>
              <Space direction="vertical">
                <Radio value={true}>
                  <span style={{ color: "black", opacity: 1 }}>
                    Has Allergies
                  </span>
                </Radio>
                <Radio value={false}>
                  <span style={{ color: "black", opacity: 1 }}>
                    Do not have Allergies
                  </span>
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Spin>
      </Flex>

      {multiStep ? null : isEditing ? (
        <Flex justify="flex-end" gap={10}>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isUpdating || isCreating}
          >
            {allergiesInfo && allergiesInfo.length > 0 ? "Update" : "Save"}
          </Button>
        </Flex>
      ) : (
        <Button onClick={() => setIsEditing(true)}>Edit</Button>
      )}

      {multiStep && (
        <StepControls isLoading={isCreating} onNextClick={form.submit} />
      )}
    </Form>
  );
};

export default AllergiesForm;
