import { Flex } from 'antd'
import styled from 'styled-components'

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #a8a8aa;
`

const ValueText = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #242235;
`

const StatCounter = ({ icon, value }) => {
  return (
    <Flex align="center" gap="small" vertical>
      <IconWrapper>{icon}</IconWrapper>
      <ValueText>{value}</ValueText>
    </Flex>
  )
}

export default StatCounter
