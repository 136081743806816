import StepControls from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/StepControls";
import EmptyState from "@/components/ui/empty/Empty";
import ErrorAlert from "@/components/ui/errorAlert";
import {
  useCreateCCMGoalMutation,
  useGetCCMGoalMappingQuery,
  useGetCCMGoalsQuery,
} from "@/store/features/ccm/ccmApiSlice";
import { setCurrentStep } from "@/store/features/ccm/ccmFormSlice";
import { Button, Checkbox, Flex, Form, Space, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const GoalsForm = ({ multiStep = false }) => {
  const [form] = Form.useForm();
  const { patientId } = useParams();
  const dispatch = useDispatch();
  const { currentStep } = useSelector((state) => state.ccmForm);
  const [isEditing, setIsEditing] = useState(false);

  const { data: goals, isLoading: isGoalsLoading } = useGetCCMGoalsQuery();
  const { data: patientGoals, isLoading: isPatientGoalsLoading } =
    useGetCCMGoalMappingQuery({ patientId, pageNumber: 1, pageSize: 100 });
  const [createGoals, { isLoading: isCreating }] = useCreateCCMGoalMutation();

  useEffect(() => {
    if (patientGoals && patientGoals.length > 0) {
      const selectedGoals = patientGoals.map((goal) => goal.ccmGoalId);
      form.setFieldsValue({ selectedGoals, isInitial: false });
      setIsEditing(false);
    } else {
      setIsEditing(multiStep);
    }
  }, [patientGoals, form, multiStep]);

  const handleFinish = async (values) => {
    const payload = {
      ccmGoalMappingList: values.selectedGoals.map((ccmGoalId) => ({
        ccmGoalId,
      })),
      patientId,
      isInitial: !values?.isInitial ? false : true,
    };

    await createGoals(payload);

    setIsEditing(false);
    if (multiStep) {
      dispatch(setCurrentStep(currentStep + 1));
    }
  };

  const handleCancel = () => {
    if (patientGoals && patientGoals.length > 0) {
      const selectedGoals = patientGoals.map((goal) => goal.ccmGoalId);
      form.setFieldsValue({ selectedGoals });
    } else {
      form.resetFields();
    }
    setIsEditing(false);
  };

  if (multiStep && currentStep !== 1) return null;

  if (isGoalsLoading || isPatientGoalsLoading) {
    return <EmptyState />;
  }

  return (
    <Form form={form} layout="vertical" onFinish={handleFinish}>
      <ErrorAlert />
      <Flex vertical gap={10}>
        <Typography.Title level={5} style={{ margin: "0px 15px" }}>
          What are the patient&apos;s goals for disease management?
        </Typography.Title>

        <Spin spinning={isGoalsLoading || isPatientGoalsLoading}>
          <Form.Item name="selectedGoals">
            <Checkbox.Group disabled={multiStep ? false : !isEditing}>
              <Space direction="vertical">
                {goals?.map((option) => (
                  <Checkbox key={option.name} value={option.ccmGoalId}>
                    <span style={{ color: "black", opacity: 1 }}>
                      {option.name}
                    </span>
                  </Checkbox>
                ))}
              </Space>
            </Checkbox.Group>
          </Form.Item>
        </Spin>
      </Flex>

      {multiStep ? null : isEditing ? (
        <Flex justify="flex-end" gap={10}>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button type="primary" htmlType="submit" loading={isCreating}>
            {patientGoals && patientGoals.length > 0 ? "Update" : "Save"}
          </Button>
        </Flex>
      ) : (
        <Button onClick={() => setIsEditing(true)}>Edit</Button>
      )}

      {multiStep && (
        <StepControls isLoading={isCreating} onNextClick={form.submit} />
      )}
    </Form>
  );
};

export default GoalsForm;
