import { StyledMainContainer, StyledCheckBoxContainer } from './Style'
import { Typography, Select, Input, Checkbox } from 'antd'
const onChange = (checkedValues) => {
  console.log('checked = ', checkedValues)
}

const options = [
  {
    label: 'Has Risks',
    value: 'yes',
  },
  {
    label: 'Moderate Risks',
    value: 'moderate',
  },
  {
    label: 'Low Risks',
    value: 'Low',
  },
]

export default function FormContent() {
  return (
    <StyledMainContainer>
      <h3>
        1 OF 1{' '}
        <span style={{ color: 'gray' }}>
          IN THE PATIENT RISK CLASSIFICATION SECTION
        </span>
      </h3>
      <h1>What is the patient risk classificaiton?</h1>

      <StyledCheckBoxContainer>
        <Checkbox.Group
          options={options}
          onChange={onChange}
          style={{ display: 'flex', flexDirection: 'column', gap: '13px' }} // Ensure vertical alignment
        />
      </StyledCheckBoxContainer>
    </StyledMainContainer>
  )
}
