import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Flex, Form, Typography, Button, Spin } from "antd";
import { setCurrentStep } from "@/store/features/ccm/ccmFormSlice";
import RichTextEditor from "@/components/shared/richTextEditor";
import ErrorAlert from "@/components/ui/errorAlert";
import StepControls from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/StepControls";
import {
  useGetCCMAdditionalInfoQuery,
  useCreateCCMAdditionalNotesMutation,
  useUpdateCCMAdditionalNotesMutation,
} from "@/store/features/ccm/ccmApiSlice";

const AdditionalNotesForm = ({ multiStep = false }) => {
  const [form] = Form.useForm();
  const { patientId } = useParams();
  const dispatch = useDispatch();
  const { currentStep } = useSelector((state) => state.ccmForm);
  const [isEditing, setIsEditing] = useState(false);

  const { data: additionalNotes, isLoading: isNotesLoading } =
    useGetCCMAdditionalInfoQuery({
      patientId,
      pageNumber: 1,
      pageSize: 1,
      ccmAdditionalInfoTypeId: 1,
    });
  const [createNotes, { isLoading: isCreating }] =
    useCreateCCMAdditionalNotesMutation();
  const [updateNotes, { isLoading: isUpdating }] =
    useUpdateCCMAdditionalNotesMutation();

  useEffect(() => {
    if (additionalNotes && additionalNotes.length > 0) {
      form.setFieldsValue({ description: additionalNotes[0].description });
      setIsEditing(false);
    } else {
      setIsEditing(multiStep);
    }
  }, [additionalNotes, form, multiStep]);

  const handleFinish = async (values) => {
    const payload = {
      ...values,
      ccmAdditionalInfoTypeId: 1,
      patientId,
      isInitial: true,
    };
    if (additionalNotes && additionalNotes.length > 0) {
      await updateNotes({
        ...payload,
        ccmAdditionalInfoId: additionalNotes[0].ccmAdditionalInfoId,
        isInitial: false,
      });
    } else {
      await createNotes(payload);
    }
    setIsEditing(false);
    if (multiStep) {
      dispatch(setCurrentStep(currentStep + 1));
    }
  };

  const handleCancel = () => {
    if (additionalNotes && additionalNotes.length > 0) {
      form.setFieldsValue({ description: additionalNotes[0].description });
    } else {
      form.resetFields();
    }
    setIsEditing(false);
  };

  if (multiStep && currentStep !== 10) return null;

  return (
    <Form form={form} layout="vertical" onFinish={handleFinish}>
      <ErrorAlert />
      <Flex vertical gap={10}>
        <Typography.Title level={5} style={{ margin: "0px 15px" }}>
          Additional Notes
        </Typography.Title>

        <Spin spinning={isNotesLoading}>
          <RichTextEditor
            form={form}
            keyName="description"
            initialValue={additionalNotes?.[0]?.description || ""}
            sx={{ marginBottom: "30px" }}
            readOnly={multiStep ? false : !isEditing}
          />
        </Spin>
      </Flex>

      {multiStep ? null : isEditing ? (
        <Flex justify="flex-end" gap={10}>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isUpdating || isCreating}
          >
            {additionalNotes && additionalNotes.length > 0 ? "Update" : "Save"}
          </Button>
        </Flex>
      ) : (
        <Button onClick={() => setIsEditing(true)}>Edit</Button>
      )}

      {multiStep && (
        <StepControls isLoading={isCreating} onNextClick={form.submit} />
      )}
    </Form>
  );
};

export default AdditionalNotesForm;
