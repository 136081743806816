import {
  BarChartOutlined,
  CalendarOutlined,
  FieldTimeOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
import { Card, Radio, Space, Typography } from "antd";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { StyledDivContainer, StyledFormContainer } from "./styles";

const data = [
  {
    name: "05-22-2023",
    pv: 2400,
    amt: 2400,
  },
  {
    name: "05-1-2023",
    pv: 1398,
    amt: 2210,
  },
  {
    name: "25-2-2024",
    pv: 9800,
    amt: 2290,
  },
  {
    name: "15-4-2024",
    pv: 3908,
    amt: 2000,
  },
  {
    name: "05-22-2024",
    pv: 4800,
    amt: 2181,
  },
];

export default function PatientTemperatureDetails() {
  return (
    <div
      className="MainContainer"
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        paddingRight: "12px",
      }}
    >
      {/* Text $ Filters BTNS */}

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "start",
          justifyContent: "space-between",
          marginTop: "20px",
          marginBottom: "10px",
        }}
      >
        <Typography.Title level={4}>
          Result Summary(June 2023)-9 Day's
        </Typography.Title>
        <Space>
          <Radio.Group>
            <Radio.Button>
              <MenuFoldOutlined />
            </Radio.Button>
            <Radio.Button
              style={{ backgroundColor: "#1677ff", color: "white" }}
            >
              <BarChartOutlined />
            </Radio.Button>
          </Radio.Group>
        </Space>
      </div>

      {/* {Cards.....} */}

      <div
        className="cards"
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <Card
          style={{
            width: 280,
            height: 90,
            paddingBottom: "4px",
          }}
        >
          <Space
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Space style={{ display: "flex", flexDirection: "column" }}>
              <p>Last Reading</p>
              <h3>4 Days Ago</h3>
            </Space>
            <FieldTimeOutlined
              style={{
                fontSize: "28px",
                color: "white",
                backgroundColor: "#1677ff",
                padding: "10px",
                borderRadius: "15px",
              }}
            />
          </Space>
        </Card>

        <Card
          style={{
            width: 280,
            height: 90,
            paddingBottom: "4px",
          }}
        >
          <Space
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Space style={{ display: "flex", flexDirection: "column" }}>
              <p>Next Reading</p>
              <h3>June 05 at 10:00 am</h3>
            </Space>
            <CalendarOutlined
              style={{
                fontSize: "28px",
                color: "white",
                backgroundColor: "#1677ff",
                padding: "10px",
                borderRadius: "15px",
              }}
            />
          </Space>
        </Card>

        <Card
          style={{
            width: 280,
            height: 90,
            paddingBottom: "4px",
          }}
        >
          <Space
            style={{ width: "100%", display: "flex", flexDirection: "row" }}
          >
            <Space
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <p>Maximum Pulse(Last 30 days)</p>
              <h3>139</h3>
            </Space>
          </Space>
        </Card>

        <Card
          style={{
            width: 280,
            height: 90,
            paddingBottom: "4px",
          }}
        >
          <Space
            style={{ width: "100%", display: "flex", flexDirection: "row" }}
          >
            <Space
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <p>Average Pulse(Last 30 days)</p>
              <h3>94</h3>
            </Space>
          </Space>
        </Card>
      </div>

      {/* Text & Charts */}

      <StyledFormContainer>
        <StyledDivContainer>
          {/* Text Above the Chart */}

          <Typography.Text style={{ fontSize: "20px", fontWeight: "bold" }}>
            Temperature Trend Over Last 15 Reading(s)
          </Typography.Text>
          <Typography.Text>
            Time Period : May 15,2023 June 09,2023
          </Typography.Text>

          {/* Line chart */}

          <LineChart
            data={data}
            width={1100}
            height={250}
            style={{ marginTop: "30px" }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="pv"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
            <Line type="monotone" stroke="#82ca9d" />
          </LineChart>
        </StyledDivContainer>
      </StyledFormContainer>
    </div>
  );
}
