import { StyledMainContainer, StyledCheckBoxContainer } from './Style'
import { Checkbox } from 'antd'
const onChange = (checkedValues) => {
  console.log('checked = ', checkedValues)
}

const options = [
  {
    label: 'Depression',
    value: 'depression',
  },
  {
    label: 'Diziness',
    value: 'diziness',
  },
  {
    label: 'lake of restraints',
    value: 'lake',
  },
  {
    label: 'Self harm',
    value: 'harm',
  },
  {
    label: 'Trouble sleeping',
    value: 'sleeping',
  },
  {
    label: 'Weight pain',
    value: 'weight',
  },
  {
    label: 'Chest pain',
    value: 'chest',
  },
  {
    label: 'Unable to limit consumption of alchol',
    value: 'limit',
  },
  {
    label: 'Strong craving for alchol',
    value: 'strong',
  },
  {
    label: 'Tolerance to alchol',
    value: 'tolerance',
  },
]

export default function FormContent() {
  return (
    <StyledMainContainer>
      <h3>
        1 OF 1 <span style={{ color: 'gray' }}>IN THE SYMPTOMS SECTION</span>
      </h3>
      <h1>
        What are the some of the symptoms we'll be able to reduce or prevent
      </h1>

      <StyledCheckBoxContainer>
        <Checkbox.Group
          options={options}
          onChange={onChange}
          style={{ display: 'flex', flexDirection: 'column', gap: '13px' }} // Ensure vertical alignment
        />
      </StyledCheckBoxContainer>
    </StyledMainContainer>
  )
}
