/**
 * External dependencies
 */
import { createBrowserRouter, Navigate } from "react-router-dom";
/**
 * Internal dependencies
 */

import AdminLayout from "@/layout/admin/AdminLayout";
import PublicLayout from "@/layout/public/PublicLayout";
import CollapseForm from "@/Pages/AddNewPatientCollapse/CollapseAllComponents/Index";
import AddUser from "@/Pages/AddUser";
import CarePlanApproval from "@/Pages/CarePlanApproval";
import CreateOrder from "@/Pages/CreateNewOrder";
import Dashboard from "@/Pages/Dashboard";
import DashboardConponent from "@/Pages/DashboardComponent";
import DashboardNotesComponent from "@/Pages/DashboardNotesComponent";
import Devices from "@/Pages/Devices";
import AdminException from "@/Pages/error/AdminException";
import FileterForm from "@/Pages/Filter";
import ForgotPassword from "@/Pages/forogtPassword";
import Login from "@/Pages/login";
import Messages from "@/Pages/Messages";
import Patients from "@/Pages/Patients";
import Reports from "@/Pages/Reports";
import ResetPassword from "@/Pages/resetPassword";
import Signup from "@/Pages/signup";
import OTPVerification from "@/Pages/verifyPhone";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";

import Allergies from "@/Pages/ChronicCareManagement/Allergies";
import Barriers from "@/Pages/ChronicCareManagement/Barriers";
import Goals from "@/Pages/ChronicCareManagement/Goals";
import Risk from "@/Pages/ChronicCareManagement/PatientRisk";
import Symptoms from "@/Pages/ChronicCareManagement/Symptoms";
import GlobalSetting from "@/Pages/GlobalSettings";
import NotificationTable from "@/Pages/NotificationTable";
import AddPatient from "@/Pages/Patients/addPatientForm";
import PatientDetailsParent from "@/Pages/Patients/patientDetails";
import PatientAssessmentDetails from "@/Pages/Patients/patientDetails/assessment";
import PatientAttachmentsDetails from "@/Pages/Patients/patientDetails/attachements";
import PatientBloodPressureDetails from "@/Pages/Patients/patientDetails/bloodPressure";
import PatientCarePlan from "@/Pages/Patients/patientDetails/carePlan";
import PatientDetails from "@/Pages/Patients/patientDetails/details";
import PatientDeviceDetails from "@/Pages/Patients/patientDetails/devices";
import PatientGlucoseDetails from "@/Pages/Patients/patientDetails/glucose";
import PatientPulseDetails from "@/Pages/Patients/patientDetails/pulse";
import PatientSpO2Details from "@/Pages/Patients/patientDetails/spo2";
import PatientTemperatureDetails from "@/Pages/Patients/patientDetails/temperature";
import PatientTimeLogDetails from "@/Pages/Patients/patientDetails/timelog";
import PatientWeightDetails from "@/Pages/Patients/patientDetails/weight";
import TaskPopup from "@/Pages/TaskPopup";
import Test from "@/Pages/test/Test";
import UserDetail from "@/Pages/UserDetail";
import {
  ADD_PATIENT_DETAILS_PATH,
  ADD_PATIENT_PATH,
  ADD_USER,
  CARE_APPROVAL_FORM,
  COLLAPSE_FORM,
  CREATE_NEW_ORDER,
  DASHBOARD_COMPONENT,
  DASHBOARD_NOTES_COMPONENT,
  DASHBOARD_PATH,
  DEVICES_PATH,
  FILTER_PATH,
  FORGOT_PASSWORD_PATH,
  LOGIN_PATH,
  MESSAGES_PATH,
  PATIENT_CHRONIC_ALLERGIES,
  PATIENT_CHRONIC_BARRIERS,
  PATIENT_CHRONIC_GOALS,
  PATIENT_CHRONIC_RISK,
  PATIENT_CHRONIC_SYMPTOMS,
  PATIENT_DETAILS_ASSESSMENT_PATH,
  PATIENT_DETAILS_ATTACHMENTS_PATH,
  PATIENT_DETAILS_BLOOD_PRESSURE_PATH,
  PATIENT_DETAILS_CARE_PLAN_PATH,
  PATIENT_DETAILS_DEVICES_PATH,
  PATIENT_DETAILS_GLUCOSE_PATH,
  PATIENT_DETAILS_PARENT_PATH,
  PATIENT_DETAILS_PATH,
  PATIENT_DETAILS_PULSE_PATH,
  PATIENT_DETAILS_SPO2_PATH,
  PATIENT_DETAILS_TEMPERATURE_PATH,
  PATIENT_DETAILS_TIMELOG_PATH,
  PATIENT_DETAILS_WEIGHT_PATH,
  PATIENT_NOTIFICATION,
  PATIENT_TASK_POPUP,
  PATIENT_USER_DETAIL,
  PATIENTS_PATH,
  REPORTS_PATH,
  RESET_PASSWORD_PATH,
  SIGN_UP_PATH,
  VERIFY_PHONE_PATH,
  GLOBAL_SETTINGS,
  PATIENT_CCM_DASHBOARD,
  DEVICES_ORDER_PATH
} from "./routeDefinitions";
import CcmDashboard from "@/Pages/CcmDashboard";
import DeviceOrder from "@/Pages/DeviceOrder";

const appRouter = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <AdminLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: <Navigate to={DASHBOARD_PATH} replace />
      },
      {
        path: DASHBOARD_PATH,
        element: <Dashboard />
      },
      {
        path: DEVICES_PATH,
        element: <Devices />
      },
      {
        path: DEVICES_ORDER_PATH,
        element: <DeviceOrder />
      },
      {
        path: MESSAGES_PATH,
        element: <Messages />
      },
      {
        path: PATIENTS_PATH,
        element: <Patients />
      },
      {
        path: ADD_PATIENT_PATH,
        element: <AddPatient />
      },
      {
        path: ADD_PATIENT_DETAILS_PATH,
        element: <CollapseForm />
      },
      {
        path: PATIENT_DETAILS_PARENT_PATH,
        element: <PatientDetailsParent />,
        children: [
          {
            index: true,
            path: PATIENT_DETAILS_PATH,
            element: <PatientDetails />
          },
          {
            path: PATIENT_DETAILS_CARE_PLAN_PATH,
            element: <PatientCarePlan />
          },
          {
            path: PATIENT_DETAILS_BLOOD_PRESSURE_PATH,
            element: <PatientBloodPressureDetails />
          },
          {
            path: PATIENT_DETAILS_PULSE_PATH,
            element: <PatientPulseDetails />
          },
          {
            path: PATIENT_DETAILS_SPO2_PATH,
            element: <PatientSpO2Details />
          },
          {
            path: PATIENT_DETAILS_WEIGHT_PATH,
            element: <PatientWeightDetails />
          },
          {
            path: PATIENT_DETAILS_GLUCOSE_PATH,
            element: <PatientGlucoseDetails />
          },
          {
            path: PATIENT_DETAILS_TEMPERATURE_PATH,
            element: <PatientTemperatureDetails />
          },
          {
            path: PATIENT_DETAILS_ASSESSMENT_PATH,
            element: <PatientAssessmentDetails />
          },
          {
            path: PATIENT_DETAILS_DEVICES_PATH,
            element: <PatientDeviceDetails />
          },
          {
            path: PATIENT_DETAILS_TIMELOG_PATH,
            element: <PatientTimeLogDetails />
          },
          {
            path: PATIENT_DETAILS_ATTACHMENTS_PATH,
            element: <PatientAttachmentsDetails />
          }
        ]
      },
      {
        path: REPORTS_PATH,
        element: <Reports />
      },
      {
        path: FILTER_PATH,
        element: <FileterForm />
      },
      {
        path: DASHBOARD_COMPONENT,
        element: <DashboardConponent />
      },
      {
        path: DASHBOARD_NOTES_COMPONENT,
        element: <DashboardNotesComponent />
      },
      {
        path: CARE_APPROVAL_FORM,
        element: <CarePlanApproval />
      },
      {
        path: ADD_USER,
        element: <AddUser />
      },
      {
        path: COLLAPSE_FORM,
        element: <CollapseForm />
      },
      {
        path: CREATE_NEW_ORDER,
        element: <CreateOrder />
      },
      {
        path: "test",
        element: <Test />
      },
      {
        path: PATIENT_CHRONIC_GOALS,
        element: <Goals />
      },
      {
        path: PATIENT_CHRONIC_BARRIERS,
        element: <Barriers />
      },
      {
        path: PATIENT_CHRONIC_SYMPTOMS,
        element: <Symptoms />
      },
      {
        path: PATIENT_CHRONIC_ALLERGIES,
        element: <Allergies />
      },
      {
        path: PATIENT_CHRONIC_RISK,
        element: <Risk />
      },
      {
        path: PATIENT_TASK_POPUP,
        element: <TaskPopup />
      },
      {
        path: PATIENT_NOTIFICATION,
        element: <NotificationTable />
      },
      {
        path: PATIENT_USER_DETAIL,
        element: <UserDetail />
      },
      {
        path: GLOBAL_SETTINGS,
        element: <GlobalSetting />
      },
      {
        path: PATIENT_CCM_DASHBOARD,
        element: <CcmDashboard />
      }
    ],
    errorElement: <AdminException />
  },
  {
    path: "/",
    element: <PublicLayout />,
    children: [
      {
        path: LOGIN_PATH,
        element: (
          <PublicRoute>
            <Login />
          </PublicRoute>
        )
      },
      {
        path: SIGN_UP_PATH,
        element: (
          <PublicRoute>
            <Signup />
          </PublicRoute>
        )
      },
      {
        path: VERIFY_PHONE_PATH,
        element: (
          <PublicRoute>
            <OTPVerification />
          </PublicRoute>
        )
      },
      {
        path: FORGOT_PASSWORD_PATH,
        element: (
          <PublicRoute>
            <ForgotPassword />
          </PublicRoute>
        )
      },
      {
        path: RESET_PASSWORD_PATH,
        element: (
          <PublicRoute>
            <ResetPassword />
          </PublicRoute>
        )
      }
    ],
    errorElement: <AdminException />
  }
]);

export default appRouter;
