import { BluetoothIcon } from "@/assets/icons";
import { Button, Flex, List, Tooltip, Typography } from "antd";
import { Fragment, useState } from "react";

import {
  ControlOutlined,
  DeleteOutlined,
  EditOutlined,
  MobileOutlined,
  PlusCircleFilled,
  PlusCircleOutlined
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import DeviceModal from "./DeviceModal";
import { useDeleteDeviceMutation } from "@/store/features/devices/deviceApiSlice";

const getIcon = (deviceType) => {
  switch (deviceType.toLowerCase()) {
    case "bluetooth":
      return <BluetoothIcon />;
    case "cellular":
      return <MobileOutlined style={{ fontSize: "1.5rem", color: "gray" }} />;
    case "manual":
      return <ControlOutlined style={{ fontSize: "1.5rem", color: "gray" }} />;
    default:
      return null;
  }
};

const DevicesList = ({ data }) => {
  const [open, setOpen] = useState(false);
  const [formInitialValue, setFormInitialValue] = useState({});

  const navigate = useNavigate();
  const [deleteDevice, { isLoading }] = useDeleteDeviceMutation();
  return (
    <Fragment>
      <List
        style={{ marginTop: "2rem" }}
        header={
          <Flex align="center" justify="space-between">
            <Typography.Text
              strong
              style={{ fontSize: "1.5rem", fontWeight: "500" }}
            >
              List of Available Devices
            </Typography.Text>

            <Flex gap="middle">
              <Button
                onClick={() => {
                  setFormInitialValue({});
                  setOpen(true);
                }}
                icon={<PlusCircleFilled style={{ color: "blue" }} />}
              >
                Add Device
              </Button>
              <Button
                onClick={() => navigate("/createNewOrder")}
                type="primary"
                icon={<PlusCircleOutlined />}
              >
                Place New Order
              </Button>
            </Flex>

            {/*modal for creating new devices */}
          </Flex>
        }
        bordered
        dataSource={data}
        renderItem={(device) => (
          <List.Item
            key={device.deviceId}
            actions={[
              <Flex gap={"middle"} key={device.serialNumber}>
                <Button
                  disabled={isLoading}
                  onClick={() => {
                    deleteDevice(device.deviceId);
                  }}
                  icon={<DeleteOutlined />}
                />
                <Button
                  onClick={() => {
                    setFormInitialValue(device);
                    setOpen(true);
                  }}
                  icon={<EditOutlined />}
                />
              </Flex>
            ]}
          >
            <List.Item.Meta
              avatar={getIcon(device.deviceType)}
              title={device.deviceName}
              description={
                <Tooltip title="Serial Number">
                  <Typography.Text type="secondary">
                    S/N: {device.serialNumber}
                  </Typography.Text>
                </Tooltip>
              }
            />
          </List.Item>
        )}
      />
      <DeviceModal
        open={open}
        setOpen={setOpen}
        initialValues={formInitialValue}
      />
    </Fragment>
  );
};

export default DevicesList;
