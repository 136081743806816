/**
 * External dependencies
 */
import { Layout } from 'antd'
import { useState } from 'react'
/**
 * Internal dependencies
 */

import Header from '@/components/ui/header'
import Sidebar from '@/components/ui/sidebar'
import { Outlet } from 'react-router-dom'
import { StyledContent, StyledLayout } from './style'

const AdminLayout = () => {
  const [collapsed, setCollapsed] = useState(false)

  return (
    <Layout>
      <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
      <StyledLayout collapsed={collapsed}>
        <Header />
        <StyledContent>
          <Outlet />
        </StyledContent>
        {/* <Footer /> */}
      </StyledLayout>
    </Layout>
  )
}
export default AdminLayout
