import { Table, Button } from "antd";
import { useGetPatientTasksQuery } from "@/store/features/patientTasks/patientTaskApiSlice";
import { useState } from "react";
const TasksTable = ({ patientId }) => {
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  });
  const { data, isLoading } = useGetPatientTasksQuery({
    ...pagination,
    patientId,
  });
  const { data: tasks, pagination: paginationData } = data || {};
  const columns = [
    { title: "Title", dataIndex: "title", key: "title" },
    { title: "Status", dataIndex: "status", key: "status" },
    { title: "Due Date", dataIndex: "dueDate", key: "dueDate" },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button type="link" onClick={() => handleEditTask(record)}>
          Edit
        </Button>
      ),
    },
  ];

  const handleEditTask = (record) => {
    // Implement edit functionality
    console.log("Editing task:", record);
  };

  return (
    <Table
      columns={columns}
      dataSource={tasks}
      rowKey={(record) => record.id}
      loading={isLoading}
      pagination={{
        pageSize: pagination.pageSize,
        current: pagination.pageNumber,
        total: paginationData?.totalItems || 0,
        onChange: (page) =>
          setPagination((prev) => ({
            ...prev,
            pageNumber: page,
          })),
      }}
    />
  );
};

export default TasksTable;
