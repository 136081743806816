import { apiSlice } from "@/store/features/api/apiSlice";
import { PATIENT_TASK_URL } from "@/constants/apiDefinition";

export const patientTasksApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPatientTasks: builder.query({
      query: ({ patientId, pageNumber, pageSize }) => ({
        url: PATIENT_TASK_URL,
        params: { PageNumber: pageNumber, PageSize: pageSize, patientId },
      }),
      transformResponse: (response, meta) => {
        const paginationHeader = meta.response.headers.get("Pagination");
        return {
          data: response,
          pagination: paginationHeader ? JSON.parse(paginationHeader) : null,
        };
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: "PatientTask", id })),
              { type: "PatientTask", id: "LIST" },
            ]
          : [{ type: "PatientTask", id: "LIST" }],
    }),
    addPatientTask: builder.mutation({
      query: (body) => ({
        url: PATIENT_TASK_URL,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "PatientTask", id: "LIST" }],
    }),
    updatePatientTask: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `${PATIENT_TASK_URL}/${id}`,
        method: "PUT",
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "PatientTask", id }],
    }),
    deletePatientTask: builder.mutation({
      query: (id) => ({
        url: `${PATIENT_TASK_URL}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [{ type: "PatientTask", id }],
    }),
  }),
});

export const {
  useGetPatientTasksQuery,
  useAddPatientTaskMutation,
  useUpdatePatientTaskMutation,
  useDeletePatientTaskMutation,
} = patientTasksApiSlice;
