/* eslint-disable react/no-unescaped-entities */
import {
  BarChartOutlined,
  CalendarOutlined,
  FieldTimeOutlined,
  LineChartOutlined,
} from "@ant-design/icons";
import { Card, Col, Flex, Radio, Row, Space, Typography } from "antd";

import EmptyState from "@/components/ui/empty/Empty";
import { useGetPatientBloodPressureByIdQuery } from "@/store/features/patientDetails/patientDetailsApiSlice";
import dayjs from "dayjs";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { analyzeBloodPressure, getPatientDataSummary } from "./../constant";
import BloodPressureLineChart from "./LineChart";
import BloodPressureScatterPlot from "./ScatterChart";
import { StyledDivContainer, StyledFormContainer } from "./styles";

export default function PatientPulseDetails() {
  const { patientId } = useParams();
  const [chartType, setChartType] = useState("line");

  const { data, isLoading, isError, isSuccess } =
    useGetPatientBloodPressureByIdQuery(parseInt(patientId));

  const handleChartTypeChange = (e) => {
    setChartType(e.target.value);
  };

  let content = null;

  if (isLoading || isError) {
    content = <EmptyState />;
  }

  if (isSuccess && data.length !== 0) {
    const summary = getPatientDataSummary(data);
    const { max, min } = analyzeBloodPressure(data);

    content = (
      <div
        className="MainContainer"
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "center",
          paddingRight: "12px",
        }}
      >
        {/* Text $ Filters BTNS */}

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "start",
            justifyContent: "space-between",
            marginTop: "20px",
            marginBottom: "10px",
          }}
        >
          <Typography.Title level={4}>
            Result Summary {summary.lastDateAndDays}
          </Typography.Title>
          <Space>
            <Radio.Group onChange={handleChartTypeChange} value={chartType}>
              <Radio.Button value="line">
                <LineChartOutlined />
              </Radio.Button>
              <Radio.Button value="bar">
                <BarChartOutlined />
              </Radio.Button>
            </Radio.Group>
          </Space>
        </div>

        {/* {Cards.....} */}

        <Row gutter={[16, 16]} justify="space-between">
          <Col span={24} md={6}>
            <Card style={cardStyle}>
              <Flex justify="space-between" align="start">
                <Space direction="vertical" size={0}>
                  <p style={{ margin: 0 }}>Last Reading</p>
                  <h3 style={{ margin: "8px 0 0 0" }}>
                    {summary.daysSinceLastEntry} Days Ago
                  </h3>
                </Space>
                <FieldTimeOutlined style={iconStyle} />
              </Flex>
            </Card>
          </Col>
          <Col span={24} md={6}>
            <Card style={cardStyle}>
              <Flex justify="space-between" align="start">
                <Space direction="vertical" size={0}>
                  <p style={{ margin: 0 }}>Next Reading</p>
                  <h3 style={{ margin: "8px 0 0 0" }}>June 05 at 10:00 am</h3>
                </Space>
                <CalendarOutlined style={iconStyle} />
              </Flex>
            </Card>
          </Col>
          <Col span={24} md={6}>
            <Card style={cardStyle}>
              <Space direction="vertical" size={0} style={{ width: "100%" }}>
                <p style={{ margin: 0 }}>
                  Maximum Reading (
                  {dayjs(max?.date).format("DD MMM YYYY HH:mm A")})
                </p>
                <h3
                  style={{ margin: "8px 0 0 0" }}
                >{`${max.systolic}/${max.diastolic}`}</h3>
              </Space>
            </Card>
          </Col>
          <Col span={24} md={6}>
            <Card style={cardStyle}>
              <Space direction="vertical" size={0} style={{ width: "100%" }}>
                <p style={{ margin: 0 }}>
                  Minimum Reading (
                  {dayjs(min?.date).format("DD MMM YYYY HH:mm A")})
                </p>
                <h3
                  style={{ margin: "8px 0 0 0" }}
                >{`${min.systolic}/${min.diastolic}`}</h3>
              </Space>
            </Card>
          </Col>
        </Row>

        {/* Text & Charts */}

        <StyledFormContainer>
          <Flex vertical>
            <Typography.Text style={{ fontSize: "20px", fontWeight: "bold" }}>
              Blood Pressure Trend Over Last {summary.formattedData.length}{" "}
              Reading(s)
            </Typography.Text>
            <Typography.Text>Time Period : {summary.dateRange}</Typography.Text>
          </Flex>
          <StyledDivContainer>
            {/* Text Above the Chart */}

            {/* Line chart */}

            {chartType === "line" ? (
              <BloodPressureLineChart data={data} yLabel={"Patient Pulse"} />
            ) : (
              <BloodPressureScatterPlot data={data} yLabel={"Patient Pulse"} />
            )}
          </StyledDivContainer>
        </StyledFormContainer>
      </div>
    );
  }

  return content;
}
const cardStyle = {
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};

const iconStyle = {
  fontSize: "28px",
  color: "white",
  backgroundColor: "#1677ff",
  padding: "10px",
  borderRadius: "15px",
};
