import { Fragment, useState } from "react";
import {
  Button,
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  Select,
  Tag,
  Flex,
  Modal,
  Typography
} from "antd";
import { UserOutlined, FormOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import useCreatePatient from "@/hooks/patient/useCreatePatient";
import useCreatePatientValidation from "@/hooks/validation/useCreatePatientValidation";

const OptionButton = styled(Button)`
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  transition: all 0.3s ease;

  &:hover,
  &.selected {
    transform: translateY(-5px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }

  .anticon {
    font-size: 24px;
    margin-bottom: 8px;
  }
`;

const OptionText = styled.span`
  font-size: 14px;
  font-weight: 500;
`;

const StyledRow = styled(Row)`
  width: 100%;
`;

const customizeRequiredMark = (label, { required }) => (
  <Fragment>
    {required ? (
      <Tag color="error">{"*"}</Tag>
    ) : (
      <Tag color="warning">optional</Tag>
    )}
    {label}
  </Fragment>
);

const MinimalPatientCreateForm = () => {
  const { validateMRN } = useCreatePatientValidation();

  const { handleCreatePatient, isSuccess } = useCreatePatient();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const onFinish = (values) => {
    handleCreatePatient(values);
    if (isSuccess) {
      setIsModalOpen(true);
    }
  };

  return (
    <Form
      onFinish={onFinish}
      requiredMark={customizeRequiredMark}
      layout="vertical"
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
          gap: "20px",
          padding: "20px",
          marginTop: "3rem"
        }}
      >
        <Form.Item
          name="mrn"
          label="MRN"
          rules={[{ required: true, validator: validateMRN }]}
        >
          <Input placeholder="MRN" />
        </Form.Item>

        <Form.Item
          name="firstName"
          label="First Name"
          rules={[{ required: true, message: "First Name is Required!" }]}
        >
          <Input placeholder="First Name" />
        </Form.Item>

        <Form.Item
          name="lastName"
          label="Last Name"
          rules={[{ required: true, message: "Last Name is Required!" }]}
        >
          <Input placeholder="Last Name" />
        </Form.Item>

        <Form.Item
          name="dob"
          label="Date of Birth"
          rules={[{ required: true, message: "Date of Birth is Required!" }]}
        >
          <DatePicker style={{ width: "100%" }} placeholder="MM-DD-YYYY" />
        </Form.Item>

        <Form.Item
          name="genderId"
          label="Gender"
          rules={[{ required: true, message: "Gender is Required!" }]}
        >
          <Select
            placeholder="Select Gender"
            options={[
              { label: "Male", value: 1 },
              { label: "Female", value: 2 },
              { label: "Non-Binary", value: 3 },
              { label: "Other", value: 4 }
            ]}
          />
        </Form.Item>

        <Form.Item
          name="address"
          label="Address"
          rules={[{ required: true, message: "Required" }]}
        >
          <Input placeholder="Address" />
        </Form.Item>

        <Form.Item
          name="city"
          label="City"
          rules={[{ required: true, message: "Required" }]}
        >
          <Input placeholder="City" />
        </Form.Item>

        <Form.Item
          name="state"
          label="State"
          rules={[{ required: true, message: "Required" }]}
        >
          <Input placeholder="State" />
        </Form.Item>

        <Form.Item
          name="zipcode"
          label="Zipcode"
          rules={[{ required: true, message: "Required" }]}
        >
          <Input placeholder="Zipcode" />
        </Form.Item>

        <Form.Item
          name="mobileNumber"
          label="Mobile Number"
          rules={[{ required: true, message: "Required" }]}
        >
          <Input placeholder="Mobile Number" />
        </Form.Item>
      </div>
      <Flex align="center" justify="center">
        <Button style={{ width: "18rem" }} type="primary" htmlType="submit">
          Create Patient
        </Button>
      </Flex>

      <Modal
        title="MRN Confirmation"
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        oKText="Create Another Patient"
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => setIsModalOpen(false)}
          >
            OK
          </Button>
        ]}
      >
        <p>Want to use this MRN?</p>
        <Typography.Text
          copyable={{
            text: "text to be copied"
          }}
        >
          Use the MRN
        </Typography.Text>
      </Modal>
    </Form>
  );
};

const PatientCreationOptions = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const navigate = useNavigate();

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    if (option === "full") {
      navigate("/patients/add"); // Replace with your actual route
    }
  };

  return (
    <Fragment>
      <StyledRow gutter={[16, 16]}>
        <Col xs={24} sm={12}>
          <OptionButton
            type={selectedOption === "full" ? "primary" : "default"}
            onClick={() => handleOptionClick("full")}
            className={selectedOption === "full" ? "selected" : ""}
          >
            <UserOutlined />
            <OptionText>Create patient with full info</OptionText>
          </OptionButton>
        </Col>
        <Col xs={24} sm={12}>
          <OptionButton
            type={selectedOption === "minimal" ? "primary" : "default"}
            onClick={() => handleOptionClick("minimal")}
            className={selectedOption === "minimal" ? "selected" : ""}
          >
            <FormOutlined />
            <OptionText>Create a patient with minimal info</OptionText>
          </OptionButton>
        </Col>
      </StyledRow>
      {selectedOption === "minimal" ? <MinimalPatientCreateForm /> : null}
    </Fragment>
  );
};

export default PatientCreationOptions;
