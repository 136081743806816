import Icon from '@ant-design/icons'



const CustomIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            enableBackground="new 0 0 512 512"
            viewBox="0 0 32 32"
        >
            <path
                fill="#263238"
                d="M28 2H4C2.346 2 1 3.346 1 5v16c0 1.654 1.346 3 3 3h3v5a1.001 1.001 0 001.625.781L15.851 24H28c1.654 0 3-1.346 3-3V5c0-1.654-1.346-3-3-3zm1 19c0 .551-.448 1-1 1H15.5a1 1 0 00-.625.219L9 26.919V23a1 1 0 00-1-1H4c-.552 0-1-.449-1-1V5c0-.551.448-1 1-1h24c.552 0 1 .449 1 1z"
                data-original="#263238"
            ></path>
            <path
                fill="#3498db"
                d="M24 12H8a1 1 0 110-2h16a1 1 0 110 2zm-8 4H8a1 1 0 110-2h8a1 1 0 110 2z"
                data-original="#3498db"
            ></path>
        </svg>

    );
}



const ChatIcon = (props) => <Icon component={CustomIcon} {...props} />

export default ChatIcon