import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  carePlan: "rpm",
  currentPatientHealthId: null,
};

const patientDetailsSlice = createSlice({
  name: "patientDetails",
  initialState,
  reducers: {
    setCarePlan: (state, action) => {
      state.carePlan = action.payload;
    },
    setCurrentPatientHealthId: (state, action) => {
      console.log(action.payload);
      state.currentPatientHealthId = action.payload;
    },
  },
});

export const { setCarePlan, setCurrentPatientHealthId } =
  patientDetailsSlice.actions;
export default patientDetailsSlice.reducer;
