import { Col, Divider, Form, Modal, Row, Select } from "antd";
import { StyledFormContainer } from "./Style";

export default function FilterForm({ open, setOpen }) {
  const [form] = Form.useForm();
  const onFinish = () => {
    const values = form.getFieldsValue(true);
    console.log("Received values of form: ", values);
  };

  const handleOk = () => {
    const values = form.getFieldsValue(true);
    console.log("Received values of form: ", values);
  };
  const handleCancel = () => {
    console.log("cancel");
    setOpen(false);
  };

  return (
    <Modal
      title="Filter Patients"
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <StyledFormContainer>
        <Form
          name="filter_form"
          onFinish={onFinish}
          layout="vertical"
          form={form}
        >
          <Row gutter={16}>
            {filterConfig.map((item) => (
              <Col xs={24} sm={12} xxl={8} key={item.name}>
                <Form.Item name={item.name} label={item.label}>
                  <Select
                    placeholder={item.label}
                    options={item.options}
                    mode={item.mode}
                  />
                </Form.Item>
              </Col>
            ))}
          </Row>
          <Divider />

          {/* Submit Button */}
          {/* <Row gutter={16}>
            <Col span={3}>
              <Form.Item>
                <Button type="" htmlType="button" onClick={handleCancel}>
                  Clear All
                </Button>
              </Form.Item>
            </Col>
            <Col span={18}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save Filter & Search
                </Button>
              </Form.Item>
            </Col>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Search
              </Button>
            </Form.Item>
          </Row> */}
        </Form>
      </StyledFormContainer>
    </Modal>
  );
}

const filterConfig = [
  {
    label: "Monitoring Program",
    name: "monitoringProgram",
    type: "select",
    mode: "multiple",
    options: [
      { value: "rpm", label: "Remote Patient Monitoring (RPM)" },
      { value: "ccm", label: "Chronic Care Management (CCM)" },
      { value: "pcm", label: "Principal Care Management (PCM)" },
      { value: "rtm", label: "Remote Therapeutic Monitoring (RTM)" },
    ],
  },
  {
    label: "Patient Compliance",
    name: "compliance",
    type: "select",
    options: [
      { value: "high", label: "High (>80%)" },
      { value: "medium", label: "Medium (50-80%)" },
      { value: "low", label: "Low (<50%)" },
    ],
  },
  {
    label: "Location",
    name: "location",
    type: "select",
    mode: "multiple",
    options: [
      { value: "urban", label: "Urban" },
      { value: "suburban", label: "Suburban" },
      { value: "rural", label: "Rural" },
    ],
  },
  {
    label: "Chronic Conditions",
    name: "conditions",
    type: "select",
    mode: "multiple",
    options: [
      { value: "diabetes", label: "Diabetes" },
      { value: "hypertension", label: "Hypertension" },
      { value: "chf", label: "Congestive Heart Failure" },
      { value: "copd", label: "COPD" },
      { value: "ckd", label: "Chronic Kidney Disease" },
    ],
  },
  {
    label: "Insurance Payers",
    name: "payers",
    type: "select",
    mode: "multiple",
    options: [
      { value: "medicare", label: "Medicare" },
      { value: "medicaid", label: "Medicaid" },
      { value: "private", label: "Private Insurance" },
      { value: "selfPay", label: "Self-Pay" },
    ],
  },
  {
    label: "Care Provider",
    name: "careProvider",
    type: "select",
    options: [
      { value: "physician", label: "Physician" },
      { value: "nurse", label: "Nurse Practitioner" },
      { value: "pa", label: "Physician Assistant" },
    ],
  },
  {
    label: "Alert Level",
    name: "alertLevel",
    type: "select",
    options: [
      { value: "high", label: "High" },
      { value: "medium", label: "Medium" },
      { value: "low", label: "Low" },
    ],
  },
  {
    label: "Monitoring Period",
    name: "monitoringPeriod",
    type: "dateRange",
  },
  {
    label: "Next Appointment",
    name: "nextAppointment",
    type: "select",

    options: [
      { value: "scheduled", label: "Scheduled" },
      { value: "notScheduled", label: "Not Scheduled" },
      { value: "overdue", label: "Overdue" },
    ],
  },
  {
    label: "Communication Preference",
    name: "communicationPreference",
    type: "select",
    mode: "multiple",

    options: [
      { value: "phone", label: "Phone" },
      { value: "email", label: "Email" },
      { value: "sms", label: "SMS" },
      { value: "app", label: "Mobile App" },
    ],
  },
];
