import { Flex } from "antd";

export default function NoteAndTime(props) {
  const { time, note } = props;
  return (
    <Flex vertical gap="small">
      <div
        style={{
          fontSize: "14px",
          fontWeight: 400,
          padding: "6px 14px",
          borderRadius: "30px",
          backgroundColor: "#F1EFEE",
          width: "95%",
        }}
      >
        {note}
      </div>
      <Flex
        gap="small"
        align="center"
        style={{ fontSize: "14px", fontWeight: 400, color: "#242235" }}
      >
        <div
          style={{
            height: "12px",
            width: "12px",
            borderRadius: "50%",
            backgroundColor: "#63A3B2",
          }}
        />
        {time}
      </Flex>
    </Flex>
  );
}
