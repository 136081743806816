import React from 'react'
import { Radio } from 'antd'
import styled from 'styled-components'

const StyledRadioGroup = styled(Radio.Group)`
  background-color: #ffffff;
  border-radius: 32px;
  padding: 10px 14px;
  display: inline-flex;
`

const StyledRadioButton = styled(Radio.Button)`
  &&& {
    height: 36px;
    line-height: 36px;
    padding: 0 16px;
    border: none;
    border-radius: 32px;
    background: #ffffff;
    color: #656565;
    font-weight: 500;
    font-size: 14px;

    &::before {
      display: none;
    }

    &:first-child {
      border-left: none;
    }

    &:hover {
      color: #333;
    }

    &.ant-radio-button-wrapper-checked {
      background-color: white;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      color: #f9f9f9;
      background-color: #242235;
      font-weight: 600;
    }

    &.ant-radio-button-wrapper-checked:not(
        .ant-radio-button-wrapper-disabled
      )::before {
      background-color: transparent;
    }
  }
`

const MonitoringSelector = ({ options,value, ...props }) => {
  return (
    <StyledRadioGroup buttonStyle="solid" {...props} defaultValue={value}>
      {options.map((option) => (
        <StyledRadioButton key={option.value} value={option.value}>
          {option.label}
        </StyledRadioButton>
      ))}
    </StyledRadioGroup>
  )
}

export default MonitoringSelector
