import { useEffect, useState, useCallback } from 'react'
import { Avatar, Divider, List, Skeleton, Typography } from 'antd'
import InfiniteScroll from 'react-infinite-scroll-component'

const FETCH_URL =
  'https://randomuser.me/api/?results=10&inc=name,gender,email,nat,picture&noinfo'

const DashboardComponent = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const loadMoreData = useCallback(async () => {
    if (loading) return

    setLoading(true)
    try {
      const response = await fetch(FETCH_URL)
      if (!response.ok) throw new Error('Network response was not ok')
      const body = await response.json()
      setData((prevData) => [...prevData, ...body.results])
    } catch (error) {
      console.error('Error loading data:', error)
    } finally {
      setLoading(false)
    }
  }, [loading])

  useEffect(() => {
    loadMoreData()
  }, [loadMoreData])
  return (
    <div
      id="scrollableDiv"
      style={{
        marginTop: '20px',
        height: 400,
        overflow: 'auto',
        padding: '0 16px',
        border: '1px solid rgba(140, 140, 140, 0.35)',
      }}
    >
      <Typography.Title style={{ textAlign: 'center', margin: '9px ' }}>
        Doctors Data
      </Typography.Title>
      <InfiniteScroll
        dataLength={data.length}
        next={loadMoreData}
        hasMore={data.length < 10}
        loader={
          <Skeleton
            avatar
            paragraph={{
              rows: 1,
            }}
            active
          />
        }
        endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
        scrollableTarget="scrollableDiv"
      >
        <List
          dataSource={data}
          renderItem={(item) => (
            <List.Item key={item.email}>
              <List.Item.Meta
                avatar={<Avatar src={item.picture.large} />}
                title={<a href="https://ant.design">{item.name.last}</a>}
                description={item.email}
              />
              <div>Content</div>
            </List.Item>
          )}
        />
      </InfiniteScroll>
    </div>
  )
}
export default DashboardComponent
