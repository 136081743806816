import { Avatar, Flex } from "antd";
import { Link } from "react-router-dom";

const PatientInfo = (props) => {
  const { name, photoUrl, patientId } = props;
  return (
    <Link to={`/patients/${patientId}/details`}>
      <Flex gap={10} align="center">
        <Avatar src={photoUrl} size={40}></Avatar>
        {name}
      </Flex>
    </Link>
  );
};

export default PatientInfo;
