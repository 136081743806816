import { useState } from "react";

import JitsiCallComponent from "./JitsiCallComponent";
import { useCreateCallMutation } from "@/store/features/audioVideoCall/audioVideoApiSlice";
import { Button, Flex } from "antd";
import { PhoneOutlined, VideoCameraOutlined } from "@ant-design/icons";

const CallInterface = ({ currentUserId, contactId }) => {
  const [activeCall, setActiveCall] = useState(null);
  const [createCall, { isLoading }] = useCreateCallMutation();

  const startCall = async (callType) => {
    const callData = {
      callerId: currentUserId,
      receiverId: contactId,
      callType: callType
    };

    try {
      const result = await createCall(callData).unwrap();
      console.log("Call initiated:", result);
      setActiveCall({
        userId: currentUserId,
        ...callData
      });
    } catch (error) {
      console.error("Failed to initiate call:", error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  const endCall = () => {
    setActiveCall(null);
    // You might want to add an API call here to inform the server that the call has ended
  };

  return (
    <div>
      {!activeCall ? (
        <Flex gap={"small"}>
          <Button
            icon={<PhoneOutlined rotate={90} />}
            onClick={() => startCall("audio")}
            disabled={isLoading}
          />

          <Button
            icon={<VideoCameraOutlined />}
            onClick={() => startCall("video")}
            disabled={isLoading}
          />
        </Flex>
      ) : (
        <div>
          <JitsiCallComponent
            userId={activeCall.userId}
            callerId={activeCall.callerId}
            receiverId={activeCall.receiverId}
            callType={activeCall.callType}
          />
          <button onClick={endCall}>End Call</button>
        </div>
      )}
    </div>
  );
};

export default CallInterface;
